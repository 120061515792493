.storyCarousel {
    display: flex;
    justify-content: center;
    margin-right: -20px;
    .slick-slider {
        box-shadow: 0px 0px 10px $color-dark-shadow;
        border-radius: 8px;
        .slick-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            &::before {
                content: none; }
            .leftArrow, .rightArrow {
                font-size: 45px;
                color: #ffffff; }
            .leftArrow {
                margin-right: 46px; }
            .rightArrow {
                margin-left: 46px; } } } }

.carouselBody {
    width: 60%; }

.slick-prev {
    left: -130px;
    width: auto; }

.slick-next {
    right: -130px;
    width: auto; }
