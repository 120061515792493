.mainnav-drawer-body {
    .ant-drawer-header {
        border: none;
        .ant-drawer-header-title {
            flex-direction: row-reverse; }

        .ant-drawer-close {
            color: white; } }
    .ant-drawer-title {
        display: flex;
        align-items: center;
        img {
            margin-top: 4px;
            height: 50px; } }
    .ant-drawer-wrapper-body, .ant-drawer-header {
        background-color: #222222 !important; }
    .ant-drawer-body {
        padding: 2rem 0; }

    // Style for each link button in drawer
    .mainnav-drawer-search-link {
        svg {
            margin: 5px 0 0 5px; } }
    .main-nav__link-each {
        position: relative;
        color: $color-deep-gray;
        width: 100%;
        height: 55px;
        padding: 0 1.5rem 0 1.5rem;
        @include display-flex;
        @include align-items(center);
        @include transition(background-color .2s);
        @include flex(1);
        white-space: nowrap;
        &:not(:last-child) {
            margin-right: 2.75rem;
            @media screen and (max-width: 1600px) {
                margin-right: 2.25rem; }
            @media #{$xxl-screen-and-down} {
                margin-right: 1.75rem; }
            @media screen and (max-width: 1366px) {
                margin-right: .75rem; } }
        span {
            display: block;
            @include transform(translateY(0px) translateX(0px));
            opacity: .75; }
        .NavLinkIconBox {
            background: url('../../../../assets/images/svg/ring.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
            background-position-y: top;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            width: 48px; }
        span,
        svg,
        svg path {
                @include transition(all .25s); }
        svg path {
                &:not(.main-nav__icon-border):not(.main-nav__icon-stroke) {
                    fill: $color-deep-gray; }
                &.main-nav__icon-stroke {
                    stroke: $color-deep-gray; } }
        &:hover,
        &.active {
            background-color: #2D2D2D;
            color: #FFFFFF;
            .NavLinkIconBox {
                background: url('../../../../assets/images/svg/ringActive.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: auto;
                background-position-y: top;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 36px;
                width: 48px; }
            span {
                color: #FFFFFF;
                opacity: 1; }
            svg {
                path {
                    &:not(.main-nav__icon-border):not(.main-nav__icon-stroke) {
                        fill: #FFFFFF; }
                    &.main-nav__icon-stroke {
                        stroke: #FFFFFF; }
                    // &.main-nav__icon-border
                    //     visibility: visible
 } } }                    //     opacity: 1
        svg {
            margin-right: 4px; } } }


