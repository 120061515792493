@import url('https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:700,500,400|Montserrat:600,500,400');

.awaiting-confirmation-block p {
    text-align: center; }
.awaiting-confirmation-1440 {
    display: flex;
    justify-content: center;
    * {
        font-family: 'Montserrat', Helvetica, Arial, serif !important; } }

.awaiting-confirmation-logo {
    display: flex;
    justify-content: center;
    img {
        max-width: 200px; } }
.awaiting-confirmation-block {
    height: 100vh;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 720px;
    h1 {
        letter-spacing: 0.5px;
        font-size: 36px;
        text-align: center;
        color: rgba(63, 61, 86, 1);
        font-weight: 500;
        margin: 25px 0 35px 0;
        @media #{$lg-screen-and-down} {
            font-size: 26px; } }
    .do-not-clo-email-link {
        color: rgba(33, 150, 243, 1);
        font-size: 1rem;
        text-align: center;
        letter-spacing: 0.2px;
        font-weight: 500;
        @media #{$lg-screen-and-down} {
            padding: 0 1rem;
            font-size: 0.8rem; } }
    .we-just-se-owing-text {
        margin-top: 20px;
        text-align: center;
        font-size: 1rem;
        letter-spacing: 0;
        @media #{$lg-screen-and-down} {
            padding: 0 1rem;
            font-size: 0.8rem;
            margin-top: 10px; }
        .span2-yNAluT {
            color: #9c27b0;
            font-weight: bold; } }

    .randomly-g-ated-words {
        background-color: rgba(241, 241, 243, 1);
        display: flex;
        align-items: center;
        padding: 10px 120px;
        font-size: 2rem;
        margin-top: 35px;
        font-family: 'Roboto', Helvetica, Arial, serif !important;
        color: black;
        border-radius: 5px;
        @media #{$lg-screen-and-down} {
            padding: 8px 80px;
            font-size: 1.5rem; } } }
