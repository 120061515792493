@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;900&family=Poppins:wght@500&display=swap');
.login-parent {
    background: #222222;
    min-height: 100vh;
    padding-top: 60px;
    @media #{$lg-screen-and-down} {
        padding-top: 10px; }
    .logo-container {
        display: flex;
        justify-content: start;
        margin-left: 60px;
        margin-top: 60px;
        @media #{$lg-screen-and-down} {
            justify-content: center;
            margin-left: 0; }
        .reload-logo {
            // height: 78px
            height: 45px;
            margin-bottom: 60px;
            @media #{$lg-screen-and-down} {
                margin-top: 30px;
                height: 40px; } }

        .mobile-screen {
            margin-left: 537px;
            position: absolute;
            width: 600px;
            overflow: hidden;
            @media #{$lg-screen-and-down} {
                display: none; } } }

    .login-col-left {
        display: flex;
        margin-top: 30px;
        padding: 0 20px 0 60px;
        flex-direction: column;
        @media #{$lg-screen-and-down} {
            padding: 0 20px; }
        h1 {
            font-family: 'Lato';
            font-style: normal;
            font-weight: 900;
            font-size: 42px;
            color: #ffffff;
            line-height: 50px;
            margin: 18px 0;
            @media #{$lg-screen-and-down} {
                font-size: 26px !important;
                line-height: 28px; } }
        p {
            font-family: 'Lato';
            font-style: normal;
            font-size: 16px;
            background: linear-gradient(to left, #fa5521, #f7d100);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            color: transparent;
            @media #{$lg-screen-and-down} {
                margin-bottom: 60px; } } }
    .login-col-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px 0 10px;
        margin-top: 100px;
        @media #{$lg-screen-and-up} {
            align-items: flex-end;
            padding: 0 70px 0 50px; }
        .form-slogan-text {
            background: #2F92ED;
            background: linear-gradient(to bottom, #2F92ED 0%, #00CFAA 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-family: 'Poppins';
            font-size: 16px;
            text-align: center;
            @media #{$lg-screen-and-up} {
                text-align: right; } }
        .dots {
            position: absolute;
            left: 0px;
            top: 190px;
            width: 80px;
            @media #{$lg-screen-and-down} {
                display: none; } }

        .login-form-box {
            margin-top: 14px;
            // height: 200px
            z-index: 10;
            width: 100%;
            background: linear-gradient(250deg, rgba(0, 0, 0, 0.2), rgb(250, 121, 33)), linear-gradient(90deg, #ea6615 100%, #ea6615 100%);
            box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.5);
            border-radius: 4px;
            padding: 48px 16px;
            @media #{$lg-screen-and-up} {
                margin-top: 28px;
                padding: 48px 56px; }
            .form-head {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-bottom: 16px;
                align-items: center;
                @media #{$lg-screen-and-up} {
                    margin-bottom: 6px !important; }

                .form-title {
                    font-family: 'Poppins';
                    font-size: 18px;
                    color: white; }
                .form-subtitle {
                    font-family: 'Lato';
                    font-size: 12px;
                    color: white; } }

            .form-input-container {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
                @media #{$lg-screen-and-up} {
                    margin-top: 10px;
                    flex-direction: row;
                    align-items: center !important;
                    column-gap: 14px; }
                .email-input {
                    width: 100%;
                    margin-bottom: 10px;
                    @media #{$lg-screen-and-up} {
                        margin-bottom: 0px; }
                    .ant-input-affix-wrapper {
                        border-radius: 2px !important; } }
                .magic-submit-button {
                    background-color: #000000;
                    box-shadow: none;
                    outline: none;
                    border: none;
                    color: white;
                    height: 44px;
                    border-radius: 3px !important;
                    display: flex;
                    justify-content: center; } } }
        .terms-text-container {
            color: black;
            font-family: 'Roboto', Helvetica, Arial, serif;
            text-align: center;
            padding-top: 2.2rem;
            font-size: 10.5px;
            @media #{$lg-screen-and-up} {
                text-align: left; }
            span {
                color: white; } } } }

// Info cards
.info-row {
    padding-top: 60px;
    @media #{$lg-screen-and-up} {
        padding-top: 40px;
        padding: 40px 60px 60px 60px; } }
.info-card {
    padding: 10px;
    @media #{$lg-screen-and-up} {
        padding: 10px 22px; }
    h2 {
        color: white;
        font-size: 22px;
        padding: 14px; }
    .info-text-container {
        background: #2A2E33;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        @media #{$lg-screen-and-up} {
            padding: 18px; }
        li {
            list-style: none;
            display: flex;
            column-gap: 16px;
            img {
                width: 20px;
                height: 20px;
                object-fit: contain; }
            p {
                font-family: 'Lato';
                color: white;
                font-size: 12px; } } } }



.visit-info-card {
    width: 400px;
    position: absolute;
    top: 20px;
    right: 70px;
    font-family: 'Lato', sans-serif;
    @media #{$lg-screen-and-up} {
        padding: 1px 2px; }
    .info-text-container {
        background: #ffffff;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
        border-radius: 5px;
        padding: 0px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        @media #{$lg-screen-and-up} {
            padding: 8px; }
        @media #{$sm-screen-and-down} {
            margin-left: 60px;
            width: 100%; }
        li {
            list-style: none;
            padding: 10px;

            img {
                width: 20px;
                height: 20px;
                object-fit: contain; }
            p {
                font-family: 'Lato';
                margin-top: 40px;
                font-size: 12px; }
            a {
                font-family: 'Lato';
                font-style: normal;
                font-size: 16px;
                background: linear-gradient(to left, #fa5521, #f7d100);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                color: transparent; } } } }


.login-footer {
    margin-top: 50px;
    background-color: #222222;
    height: 100px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    color: #C4C4C4;
    .login-footer-bottom {
        display: flex;
        @media #{$lg-screen-and-down} {
            flex-direction: column;
            align-items: center; }
        div, a {
            color: #C4C4C4;
            margin: 0 14px;
            font-size: 12px;
            @media #{$lg-screen-and-down} {
                margin: 6px 6px; } } } }
