@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.app {
    position: relative;
    height: 100vh;
    overflow: hidden;
    &--gray-bg {
        .app-body {
            background-color: #2A2E33;
            margin-top: 30px; } }
    &--dark-bg {
        .app-body {
            background-color: #2A2E33; } }
    &--agora-bg {
        .app-body {
            background-color: #2A2E33; } } }

.app-body {
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    &__inner {
        min-height: calc(100vh + 1px);
        padding: ($main-nav-height + $property-nav-height) 0 0 0;
        @media #{$lg-screen-and-down} {
            padding: $main-nav-height 0; } }
    &__with-nav-tertiary {
        margin-top: 6rem; } }
