.backdrop-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparentize(#000000, .25);
    z-index: 999; // Changed from 9999 to 999 to allow Ant design message component's z-index to be higher
    .backdrop-overlay__close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        padding: .25rem;
        cursor: pointer;
        @media #{$lg-screen-and-down} {
            top: 0.5rem;
            right: 0.5rem; }
        svg path {
            stroke: #FFFFFF; } }
    .backdrop-overlay__body {
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        height: 100%;
        @media #{$lg-screen-and-down} {
            width: 100vw !important; }
        .loading-spinner {
            top: 4rem;
            bottom: 4rem; } } }
