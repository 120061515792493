// Hide scroll bar
.tabs-general {
    -ms-overflow-style: 0;
    scrollbar-width: 0; }
.tabs-general::-webkit-scrollbar {
    display: none !important; }

.user-settings-container {
    @media #{$lg-screen-and-down} {
        margin: 0 !important;
        padding: 0 !important; } }
.user__tabs {
    &-heading {
        background-color: $color-dark-bg;
        padding: 32px;
        @extend  %block-white-basics;
        .user__tabs-heading_wrapper {
            display: flex;
            justify-content: space-between;
            .close-settings-button {
                font-size: 1.5rem;
                @media #{$md-screen-and-up} {
                    display: none; } } }
        .page-heading {
            color: #FFFFFF; } }
    &-wrapper {
        @include display-flex;
        @include align-items(center);
        .tabs-general {
            @media #{$lg-screen-and-down} {
                width: 100%;
                overflow-x: scroll; }
            &__tab {
                font-weight: normal !important;
                color: $color-cool-gray !important;
                flex-shrink: 0;
                &.active {
                    font-weight: 600 !important;
                    color: $color-primary !important; } } } }
    .page-heading {
        margin-bottom: 1.5rem; }
    .tabs-general {
        border-bottom: 0; } }

.user__tabs-body {
    position: relative;
    margin-top: 1.5rem; }

.user__tabs-body-iframe {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: calc(100vh - 323px);
    iframe {
        width: 100%;
        height: 100%; } }

.subscription-info-trigger {
    background-color: $color-lightest;
    margin-left: auto;
    padding: .5rem;
    border-radius: $radius-alt;
    cursor: pointer;
    @media #{$lg-screen-and-down} {
        position: fixed;
        bottom: 60px;
        right: 0;
        @include box-shadow(0 0 .5rem #b4b4b4);
        z-index: 2; } }
.ant-modal--plan-subscription-info {
    .ant-modal {
        &-header {
            background-color: $color-cool-gray !important; }
        &-title {
            color: #FFFFFF; }
        &-content {
            background-color: $color-cool-gray !important;
            color: #FFFFFF;
            p {
                &:not(:last-child) {
                    margin-bottom: 1rem; } } } } }
