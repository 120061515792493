.stream {
    margin-top: -40px;
    .channel-icon-span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .channel-name-span {
            font-family: 'Lato', sans-serif;
            color: #74788D;
            margin-left: 12px; } }
    .ant-tabs {
        .ant-tabs-nav {
            @media #{$lg-screen-and-down} {
                display: none; }
            &:before {
                display: none; }
            .ant-tabs-tab {
                background: none;
                border: none;
                height: 46px;
                margin: 0 12px; }
            .ant-tabs-tab-active {
                background: -moz-linear-gradient(#2F92ED, #00CFAA);
                background: -webkit-linear-gradient(#2F92ED, #00CFAA);
                -moz-background-origin: border;
                background-origin: border-box;
                border: 1.8px solid transparent;
                border-radius: 2px;
                box-shadow: inset 0 0 12px #00cccc00,0 0 12px #00cccc00,inset -999px 0 0 #2A2E33;
                transition: none; }

            .ant-tabs-tab-disabled, .viewer-counte {
                color: white;
                cursor: text; } } }

    .ant-tabs-content-holder {
        position: relative;
        top: inherit;
        .ant-tabs-content {
            margin: 0;
            @media #{$lg-screen-and-down} {
                margin-top: 24px; }
            .iframe-container {
                width: 100vw;
                display: flex;
                justify-content: center;
                padding-top: 16px;
                iframe {
                    width: 1200px;
                    @media #{$lg-screen-and-up} {
                        min-height: 625px; }
                    @media #{$lg-screen-and-down} {
                        width: 100vw;
                        min-height: 400px; }
                    @media #{$sm-screen-and-down} {
                        min-height: 224px; } } } } }
    .tab-list {
        display: none;
        @media #{$lg-screen-and-down} {
            display: block; }
        .channel-list-heading {
            margin-top: 10px;
            margin-bottom: 18px;
            margin-left: 18px;
            color: white;
            font-family: 'Lato', sans-serif;
            font-size: 14px; }
        ul {
            flex-grow: 1;
            background: none;
            color: white;
            font-size: 16px; }
        .ant-menu-item-selected {
            background-color: #6d6d6d;
            color: white;
            font-family: 'Lato', sans-serif; }
        li {
            display: flex;
            align-items: center;
            padding: 28px 18px;
            margin: 18px 0;
            svg {
                height: 34px;
                width: 34px;
                margin-right: 18px; } } } }

