// NAV LINKS
.main-nav__links {
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    margin: auto;
    height: 100%;

    // hide main-nav_links (top navbar) on small screens
    @media #{$lg-screen-and-down} {
        display: none; }

    .main-nav__link-each {
        display: block;
        position: relative;
        color: $color-deep-gray;
        height: 100%;
        padding: 4px 1.5rem 0 .75rem;
        height: 40px;
        font-size: 13px;
        @include display-flex;
        @include align-items(center);
        @include transition(background-color .2s);
        @include flex(1);
        white-space: nowrap;
        &:not(:last-child) {
            margin-right: 2.75rem;
            @media screen and (max-width: 1600px) {
                margin-right: 2.25rem; }
            @media #{$xxl-screen-and-down} {
                margin-right: 1.75rem; }
            @media screen and (max-width: 1366px) {
                margin-right: .75rem; } }
        span {
            display: block;
            @include transform(translateY(0px) translateX(0px));
            opacity: .75; }
        .NavLinkIconBox {
            background: url('../../../../assets/images/svg/ring.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
            background-position-y: top;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            width: 48px; }
        span,
        svg,
        svg path {
                @include transition(all .25s); }
        svg path {
                &:not(.main-nav__icon-border):not(.main-nav__icon-stroke) {
                    fill: $color-deep-gray; }
                &.main-nav__icon-stroke {
                    stroke: $color-deep-gray; } }
        &:not(.active) {
            &:hover {
                span {
                    @include transform(translateX(4px) translateY(0px)); } } }
        &:hover,
        &.active {
            border-radius: 5px;
            color: #fff;
            span {
                color: #FFFFFF;
                opacity: 1; }
            svg {
                path {
                    &:not(.main-nav__icon-border):not(.main-nav__icon-stroke) {
                        fill: #fff; }
                    &.main-nav__icon-stroke {
                        stroke: #fff; } } }
            .NavLinkIconBox {
                background: url('../../../../assets/images/svg/ringActive.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: auto;
                background-position-y: top;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 36px;
                width: 48px; } }
        svg {
            margin-right: 4px; } } }
