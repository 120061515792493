.user__profile-section {
    padding: 56px 32px;
    width: 100%;
    @extend %block-white-basics;
    &-inner {
        @include display-flex;
        margin-right: -1rem;
        margin-left: -1rem;
        @media #{$lg-screen-and-down} {
            flex-direction: column; }
        &-section {
            @include display-flex;
            @include flex-direction(column);
            @include flex(0 0 50%);
            max-width: 50%;
            padding-right: 1rem;
            padding-left: 1rem;
            @media #{$lg-screen-and-down} {
                max-width: 100%; }
            &-top {
                @include display-flex;
                @include justify-content(center);
                .property__image-upload {
                    height: 180px;
                    width: 180px;
                    background-size: cover;
                    border-radius: 100px;
                    margin: 1rem 0 0.5rem 0; }
                &--agency {
                    .upload-image-preview {
                        margin-top: 2.25rem; } } }
            &-bottom {
                font-size: 12px;
                color: #4F4F4F;
                line-height: 1.4;
                margin-top: auto;
                &-switch {
                    &-wrapper {
                        @include display-flex;
                        @include align-items(center);
                        margin-bottom: 36px; }
                    &-text {} }
                .ant-switch {
                    min-width: 51px; } }
            &--right {
                // background-color: $color-lightest
                padding-top: 1.5rem; } } }
    &-row {
        @include display-flex;
        justify-content: center;
        margin-right: -1.25rem;
        margin-left: -1.25rem;
        @media #{$lg-screen-and-down} {
            flex-direction: column; } }
    &-col {
        @include display-flex;
        @include flex(0 0 50%);
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        max-width: 50%;
        @media #{$lg-screen-and-down} {
            max-width: 100%; } } }

.user__profile-email-helper {
    display: block;
    font-size: 12px;
    color: $color-dark;
    line-height: 2;
    &-icon {
        margin-right: .75rem;
        svg {
            width: 1.25rem;
            height: 1.25rem;
            vertical-align: top; }
        &--right {
            margin-right: 0;
            margin-left: .5rem; } }
    &--success {
        color: darken($color-success, 25%); }
    &-message {
        margin-top: 3rem; } }

.user__profile-email-resend {
    display: inline-block;
    font-size: 12px;
    color: #999999;
    text-decoration: underline;
    margin-top: 1.5rem;
    cursor: pointer;
    &:hover {
        color: $color-primary; }
    &--sending {
        color: inherit;
        cursor: unset;
        text-decoration: none;
        &:hover {
            color: unset; }
        .sending {
            display: inline-block;
            margin-left: .75rem; } } }


.user__profile-section--left-inner {
    @include display-flex;
    margin-right: -.75rem;
    margin-left: -.75rem; }

.user__profile-section--left-right {
    padding-top: 1rem;
    @include display-flex;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(space-between);
    @include flex(1); }

.user__profile-change-email-address {
    .ant-btn {
        margin-top: .75rem;
        width: 100%; } }

.user__profile-email-form {
    margin-top: 1.5rem; }

.user__profile-section-left-helper-text {
    color: #888888;
    margin-top: 2.25rem; }

.user__profile-section-right-switch-wrapper {
    @include display-flex;
    @include align-items(center);
    color: #888888;
    .user__profile-section-right-switch-text {
        margin-right: .5rem; } }

.user__profile-section-right-helper-text {
    display: block;
    color: #888888;
    margin-top: 1.5rem; }
