// SEARCH
.main-nav__search {
    cursor: text;
    display: flex;
    align-items: center;
    .search-button {
        color: white;
        box-shadow: none;
        @media #{$md-screen-and-up} {
            display: none; } }
    .search-form {
        @media #{$lg-screen-and-down} {
            display: none; } }
    .main-nav__search-input,
    .main-nav__search-input .ant-input {
        background-color: $color-dark-bg !important; }
    .main-nav__search-input {
        border-color: $color-dark-bg;
        width: 250px;
        border-radius: $radius-alt;
        padding: 0.3125rem 1.125rem;
        @media #{$xxl-screen-and-down} {
            width: 200px; }
        .ant-input {
            font-size: 14px;
            color: $color-deep-gray;
            &::placeholder {
                font-size: 14px;
                color: $color-deep-gray; }
            &::-webkit-input-placeholder {
                font-size: 14px;
                color: $color-deep-gray; }
            &:-ms-input-placeholder {
                font-size: 14px;
                color: $color-deep-gray; } }
        .ant-input {
            &-prefix {
                margin-right: .6125rem;
                svg path {
                    fill: $color-deep-gray; } }
            &-suffix {
                .ant-input-clear-icon {
                    svg path {
                        fill: $color-deep-gray; } } } } } }
