.property-notepad {}

.ant-drawer {
    &--property-notepad {
        .ant-drawer {
            &-content {
                background-color: unset; }
            &-header {
                background-color: $color-dark-bg;
                padding-top: 11px;
                padding-bottom: 11px; }
            &-title {
                @include display-flex;
                @include align-items(center);
                svg {
                    width: 18px;
                    height: 18px;
                    path {
                        fill: $color-primary; } }
                span {
                    font-size: 14px;
                    color: #FFFFFF;
                    margin-left: .5rem;
                    @include transform(translateY(1px)); } }
            &-body {
                background-color: #FFFFFF;
                .quill {
                    height: 85%; } }
            &-close {
                padding: 8px 20px;
                svg path {
                    stroke: #FFFFFF; } } } } }
