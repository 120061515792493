// MAIN NAV
.main-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: $color-dark-bg;
    height: $main-nav-height;
    z-index: 999;
    * {
        font-family: $font-heading; }
    &__logo {
        height: 100%;
        a,
        img {
            height: 100%; } }
    &__inner {
        @include display-flex;
        @include align-items(center);
        justify-content: space-between;
        height: 100%;
        margin: auto;
        padding: 2.25rem;
        box-shadow: 0px 0px 2px $color-dark-shadow;
        @media #{$lg-screen-and-down} {
            padding: 0 1rem; }
        .main-nav-right {
            display: flex;
            align-items: center; } } }


.main-nav__toggle-searchbar {
    .search-button {
        display: none; }
    .main-nav-right {
        display: flex;
        @media #{$lg-screen-and-down} {
            flex-grow: 1; }
        .main-nav__search {
            flex-grow: 1;
            .search-form {
                display: block !important;
                flex-grow: 1;
                .main-nav__search-input {
                    width: 100%; }
                .searchIcon {
                    @media #{$lg-screen-and-down} {
                        display: none !important; } } } } }
    .main-nav-app-logo {
        display: none !important; } }

.closeSearchIcon {
    @media #{$md-screen-and-up} {
        display: none !important; } }


