// Hide scroll bar
.stories-bottombar {
    -ms-overflow-style: 0;
    scrollbar-width: 0; }
.stories-bottombar::-webkit-scrollbar {
    display: none !important; }

.sidebar-right-container {
    @media #{$lg-screen-and-up} {
        height: calc( 100vh - 52px );
        position: sticky;
        top: 52px;
        align-self: flex-start; }
    @media #{$xl-screen-and-down} {
        display: none; }
    .sidebar-right-scrollable {
        transition: opacity 0.3s;
        @media #{$lg-screen-and-up} {
            height: 80vh;
            overflow-y: auto; } }
    .sidebar-hide {
        opacity: 0;
        transition: opacity 0.3s; } }

.sidebar-toggler-container {
    width: auto;
    height: 45px;
    background-color: #222222;
    margin: 25px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    padding: 6px;
    @media #{$lg-screen-and-down} {
        display: none; }
    button {
        flex-grow: 1;
        border: none;
        outline: none;
        border-radius: 6px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        font-size: 16px;
        background-color: transparent;
        cursor: pointer;
        color: #74788D; }
    .toggle_sidebar_btn_active {
        background-color: $color-dark-light-bg;
        color: $color-white;
        // span
        //  // background: #2F92ED
        //  // background: -webkit-linear-gradient(to bottom, #2F92ED 0%, #00CFAA 100%)
        //  // background: -moz-linear-gradient(to bottom, #2F92ED 0%, #00CFAA 100%)
        //  // background: linear-gradient(to bottom, #2F92ED 0%, #00CFAA 100%)
        //  // -webkit-background-clip: text
 } }        //  // -webkit-text-fill-color: transparent

.story-sidebar {
    // ".story-sidebar" styles are also defined in StorySidebarLeft.scss file
    @media #{$lg-screen-and-down} {
        position: relative;
        top: inherit; }

    &--right {
        right: 0;
        margin: 1px 25px;
        .stories-feed-block--sidebar {
            background-color: transparent;
            padding: 18px 0;
            border: 0;
            .story-sidebar {
                &__profile {
                    @include display-flex;
                    @include flex-direction(column);
                    @include align-items(center);
                    &-image {
                        //background: $gradient-general
                        margin-bottom: 1rem;
                        padding: 2px;
                        border-radius: 50%;
                        .photo-preview {
                            width: 70px;
                            height: 70px;
                            border-radius: 50%;
                            outline: 0; } }

                    &-title {
                        color: $color-light; }
                    &-subtitle {
                        color: $color-light !important;
                        margin-top: .5rem; } }
                &__right-links {
                    margin-top: 36px;
                    li a {
                        padding-right: 36px;
                        padding-left: 36px;
                        color: $color-light;
                        &.active {
                            color: $color-light;
                            background: $color-dark-light-bg;
                            svg path {
                                &.save-icon-outlined-tick {
                                    fill: $color-light; } }
                            .ant-tag {
 } }                                // background-color: $color-primary !important
                        .ant-tag {
                            background-color: #ffffff00 !important;
                            margin-right: 0;
                            margin-left: auto;
                            padding: 0 6px;
                            line-height: 18px; } } } } } }
    &__ad {
        margin: 25px;
        margin-bottom: 100px;
        border-radius: 8px;
        @include box-shadow($box-shadow-block);
        overflow: hidden;
        img {
            display: block;
            margin: auto;
            width: 100%;
            box-shadow: $box-shadow-general; } } }

.stories-bottombar {
    position: fixed;
    height: 60px;
    width: 100vw;
    bottom: 0;
    z-index: 1;
    background-color: white;
    box-shadow: 0px -1px 8px 0px rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    padding: 0 1.3rem;
    justify-content: space-around;

    @media #{$xl-screen-and-up} {
        display: none; }
    a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #252525;
        padding: 0 8px;
        &.active {
            color: $color-primary;
            svg {
                path {
                    fill: $color-primary; } } }

        svg {
            width: 2rem;
            font-size: 1.3rem;
            path {
                fill: #252525; } }
        span {
            font-size: 0.7rem;
            padding-top: 4px; }
        .ant-tag {
            background-color: #ffffff00 !important;
            color: #131313;
            margin-right: 0;
            margin-left: auto;
            padding: 0 6px;
            line-height: 18px; } }

    .stories-fav-button {
        position: absolute;
        bottom: 5rem;
        right: 2rem;
        z-index: 1;
        box-shadow: 0px 2px 6px 0px #888888;
        height: 50px;
        width: 50px; } }
