.files-upload {
    &__blocks {
        @include display-flex;
        @include flex-wrap(wrap);
        gap: .75rem; }
    &__block {
        width: 80px;
        height: 85px;
        border-radius: 4px;
        border: 1px solid $color-light-gray;
        &--upload {
            background-color: white;
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            box-shadow: 0 4px 4px #00000020;
            cursor: pointer;
            &:hover {
                box-shadow: 0 6px 6px #00000020; } } } }

