.story-each {
    &__actions {
        @include display-flex;
        margin-top: 1rem;
        padding-top: 13px;
        @media #{$lg-screen-and-down} {
            flex-direction: column; }
        &-col {
            @include display-flex;
            @include align-items(center);
            justify-content: space-between;
            &--left {
                @media #{$lg-screen-and-down} {
                    display: none; }
                .ant-select {
                    width: 160px;
                    background: $color-dark-drop-bg;
                    color: $color-light; } }
            &--right {
                margin-left: auto;
                @media #{$lg-screen-and-down} {
                    margin-left: 0; } } } }
    &__action-each {
        @include display-flex;
        @include align-items(center);
        padding: 4px 8px;
        border-radius: 4px;
        cursor: pointer;
        @include transition(all .15s);
        &:not(:first-child) {
            margin-left: 24px; }
        &:hover {
            background-color: $color-dark-light-bg;
            .story-each__action-icon {
                svg path {
                    stroke: $color-primary; } } }
        &--light {
            &:hover {
                svg path {
                    stroke: unset !important;
                    fill: $color-primary; } } } }
    &__action-icon {
        svg {
            width: 22px;
            height: 22px; } }
    &__action-label {
        color: $color-cool-gray;
        margin: 0 .5rem; }
    &__action-count {
        font-size: 12px;
        background: transparentize($color-primary, .9);
        color: $color-primary;
        padding: 1px 7px;
        border-radius: 4px; } }
