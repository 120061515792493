.loading-spinner {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    z-index: 99; }
