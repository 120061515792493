.heading-and-description,
.heading-and-description__inner {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center); }
.heading-and-description {
    min-height: 100vh;
    &__inner {
        padding: 4rem;
        @include box-shadow(0 1px 5px 0 #00000020); }
    &__logo {
        margin-bottom: 2.5rem;
        svg,
        img {
            // For img
            // width: 9rem
            // For svg
            // width: 10rem
 } }            // height: 36px
    &__switching-icon {
        width: 4.5rem;
        margin-bottom: 1.5rem; }
    &__switching-text {
        font-size: 20px;
        font-weight: 500;
        max-width: 80px;
        max-width: 280px;
        text-align: center;
        word-break: break-word; }
    .heading-and-description__heading {
        font-size: 2rem;
        font-weight: 500;
        text-transform: capitalize;
        margin-bottom: 1.5rem;
        &--primary {
            color: $color-primary; }
        &--error {
            color: $color-error; } }
    .heading-and-description__description {
        font-size: 1rem;
        font-weight: 500;
        color: #797979;
        text-align: center;
        line-height: 2; } }
