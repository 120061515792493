.photo-preview {
    background-color: $color-lightest;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 2rem;
    height: 2rem;
    border-radius: 6px;
    outline: 1px solid #2196F3;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    svg {
        display: block;
        width: 1rem;
        height: 1rem;
        path {
            stroke: $color-soft-gray; } } }
