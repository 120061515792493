.create {
  width: 36.88px;
  height: 48.02px;
}

.card {
  height: 300px !important;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 10px 0px rgba(0, 0, 0, 0.09);
  border-radius: 20px;
  margin: 30px;
  color: #9c27b0;
}

.reno-color {
  color: #9c27b0;
}

.properties_btn {
  border: none;
  box-shadow: none;
  height: 40px;
  padding: 0px;
}

.properties_img {
  height: 34px;
}

.right {
  float: right;
  display: inline-flex;
}

.user_img {
  width: 30px;
  height: 30px;
  border: 3px solid transparent;
  border-radius: 50%;
  margin-right: 6.1px;
  background: linear-gradient(to top, white, white),
    linear-gradient(90deg, #2196f3, #0065b5);
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
}

.properties_p {
  margin: 10px 20px 0px 0px;
}

.menu_icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.create_margin {
  margin-top: 106px;
}

.ant-card-head-title {
  display: inline-block;
  padding: 10px 0px 0px 0px;
  overflow: hidden;
  width: 85%;
  font-size: 14px;
  color: #3f3d56;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: none;
  text-align: start;
}

.reno-color {
  color: #2196f3;
}

.extra {
  width: 19px;
}

.ant-tabs-content-holder {
  position: absolute;
  left: 0px;
  top: 60px;
  width: 100%;
}

.card-menu {
  width: 166px;
  height: 90px;
  cursor: pointer;
  border-radius: 20px;
  margin: 20px;
  padding: 17px;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.12),
    0 5px 10px 0px rgba(0, 0, 0, 0.09);
}

.card-head {
  display: inline-flex;
}

.menu-img {
  width: 22px;
  height: 20px;
}

.card-num {
  margin-left: 80px;
}

.card-p {
  text-align: start;
  margin-left: 5px;
}

.input {
  width: 50%;
}

.text-start {
  text-align: start;
}

.dashboard-container {
  margin-top: 5%;
  margin-left: 5%;
  margin-right: 5%;
}

.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: 20%;
  width: 80%;
}

.card-menu-2 {
  width: 168px;
  height: 178px;
  text-align: start;
  padding: 9px;
  margin: 10px;
  box-shadow: 0 1px 2px -2px rgb(0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.22),
    0 5px 10px 0px rgba(0, 0, 0, 0.18);
}

.card-head-2 {
  display: inline-flex;
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.card-p-2 {
  margin-top: 46px;
  text-align: center;
  color: #9c27b0;
}

.upload_icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.up_icon {
  width: 14px;
  margin: 17px;
  height: 20px;
  cursor: pointer;
}

.eye_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 12px;
}

.card-icon {
  margin-top: 10px;
  cursor: pointer;
}

.card-icon_1 {
  margin-top: 37px;
}

.card-icon_2 {
  margin-top: -18px;
}

.card-icon_3 {
  margin-top: -10px;
}

.card-head-3 {
  display: inline-flex;
  font-weight: bold;
  color: black;
}

.upload-p {
  margin-right: 10px;
}

.Property_Acquisition {
  color: #2196f3;
}

.property {
  padding: 20px;
  box-shadow: 0 1px 2px -2px rgb(0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.22),
    0 5px 10px 0px rgba(0, 0, 0, 0.18);
  margin: 20px;
  border-radius: 5px;
  height: 300px;
  background: #ffff;
}

.tenancy {
  padding: 20px;
  box-shadow: 0 1px 2px -2px rgb(0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.22),
    0 5px 10px 0px rgba(0, 0, 0, 0.18);
  margin: 20px;
  border-radius: 5px;
  height: 300px;
  background: #ffff;
}

.key_information {
  padding: 20px;
  box-shadow: 0 1px 2px -2px rgb(0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.22),
    0 5px 10px 0px rgba(0, 0, 0, 0.18);
  margin: 20px;
  border-radius: 5px;
  background: #ffff;
}

.tenant {
  padding: 20px;
  box-shadow: 0 1px 2px -2px rgb(0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.22),
    0 5px 10px 0px rgba(0, 0, 0, 0.18);
  margin: 20px;
  border-radius: 5px;
  height: 390px;
  background: #ffff;
}

.pro-p {
  color: #2196f3;
  font-size: 14px;
  font-weight: 700;
}

.location-icon {
  width: 19px;
  height: 24px;
  background: #ffff;
}

.property-1 {
  margin: 10px;
  width: 100%;
  display: inline-flex;
}

.property-2 {
  margin: 10px;
  width: 100%;
  display: inline-flex;
}

.property-3 {
  margin: 10px;
  width: 100%;
  display: inline-flex;
}

.select-item {
  width: 158px;
  height: 42px;
}

.input-property {
  width: 100%;
  border-radius: 5px;
  margin-left: 30px;
}

.tenant .input {
  width: 100%;
  margin: 10px 0px 10px 0px;
}

.tenant img {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 45px;
  margin-top: 18px;
}

.entry_date {
  width: 50%;
}

.exit_date {
  width: 50%;
}

.date-picker {
  display: inline-flex;
}

.home-li {
  display: inline-flex;
  list-style: none;
  margin-top: 19px;
  margin-bottom: 18px;
}

.home-li li {
  margin-right: 103px;
}

.home-li .anticon {
  display: inline-block;
  font-size: 25px;
}

.home-li .anticon:hover {
  outline: none;
}

.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.display-inline {
  display: inline-flex;
  width: 100%;
}

.padding {
  padding: 0px 10px 0px 5px;
}

.padding-right {
  padding: 0px 10px 5px 10px;
}

.water-icon {
  width: 22px;
  height: 22px;
  float: right;
}

.gas-icon {
  width: 19px;
  height: 25px;
  float: right;
}

.electricity-icon {
  width: 17px;
  height: 23px;
  float: right;
}

.new-tenant {
  text-align: -webkit-center;
  box-shadow: 0 1px 2px -2px rgb(0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.22),
    0 5px 10px 0px rgba(0, 0, 0, 0.18);
  padding: 20px;
  margin: 20px;
  height: 390px;
  border-radius: 5px;
  background: #ffff;
}

.new-tenant-img {
  width: 43px;
  height: 43px;
}

.bed-p {
  padding: 10px 0px 0px 15px;
}

.edit-btn {
  height: 132px;
  width: 132px;
}

.text-center {
  text-align: center;
}

.dashboardicon-icon {
  width: 19px;
  height: 19px;
}

.file-icon {
  width: 23px;
  height: 16px;
}

.message-icon {
  width: 23px;
  height: 18px;
}

.note-icon {
  width: 20px;
  height: 23px;
}

.add-icon {
  width: 23px;
  height: 18px;
}

.homeicon {
  width: 23px;
  height: 23px;
}

.dashboard-ul li {
  list-style: none;
  margin-bottom: 45px;
}

.type-city {
  margin-bottom: 20px;
}

.new-story {
  border: 1px solid;
  border-radius: 20px;
  height: 278px;
  width: 300px;
}

.new-story-btn {
  top: 43%;
}

.example {
  height: 148px;
}

.user-tab {
  margin-top: 4%;
  width: 100%;
}

.tab-container {
  margin-left: 10%;
  margin-right: 10%;
}

.ant-tabs-tab {
  margin-right: 50%;
}

.ant-tabs-content-holder {
  position: absolute;
  left: 0px;
  top: 20%;
  width: 100%;
}

.ant-tabs-content {
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  margin-left: 10%;
}

.g-icon {
  height: 40px;
  width: 40px;
  margin-right: 15px;
}

.c-icon {
  height: 34px;
  width: 34px;
  float: right;
}

.u-icon {
  height: 37px;
  width: 37px;
  float: right;
}

.start {
  text-align: start;
}

.acc {
  width: 25px;
  height: 27.98px;
}

.acc-p {
  background: #3f3d56;
  color: #ffff;
  padding: 10px;
  border-radius: 5px;
}

.done-tic {
  width: 15px;
  height: 10px;
}

.float-right {
  float: right;
  margin-right: 20px;
}

.pen-icon {
  width: 36.3px;
  height: 41.9px;
}

.tenancy-p p {
  margin-top: 10px;
}

.dashboard-ul {
  margin-top: 67px;
  margin-left: -42px;
}

/* disable create property button */
.disable {
  pointer-events: none;
}

.create-new-story:focus {
  outline: 0;
}

/* For Mobile Screen CSS Start */

@media only screen and (max-width: 770px) {
  .property {
    height: 95% !important;
  }

  .tenancy {
    height: 95% !important;
  }

  .key_information {
    height: 95% !important;
  }

  .tenant {
    height: 95% !important;
  }

  .dashboard-ul {
    margin-left: -40px;
  }

  .dashboard-ul li {
    list-style: none;
    padding: 20px;
    display: inline-block;
  }

  .container {
    margin-top: 10%;
    margin-left: 5%;
    margin-right: 5%;
  }
}

/* For Mobile Screen CSS End */

.rent-input {
  margin-bottom: 20px;
}

.key-input {
  margin-bottom: 20px;
}

.elec-input {
  margin-bottom: 20px;
}

.trash-icon {
  width: 20px !important;
  height: 22px !important;
  margin-top: 0px !important;
  position: initial !important;
}

.drop-input {
  margin-top: 15px;
  margin-bottom: 15px;
}

.search-input-b {
  background: #eff2f5;
  border-radius: 20px;
  width: 278.8px;
  height: 40px;
}

.search-icon {
  width: 17.55px;
  height: 18.15px;
}

.plus-icon {
  width: 14px;
  height: 14px;
}

.input-key {
  width: 60px;
  border-radius: 5px;
  margin-left: 10px;
}

.plus-btn {
  background: none;
  border: none;
  margin: -1px;
}

.plus-btn:focus {
  outline: none;
}

.add-input-field {
  border: none;
  margin-bottom: 12px;
  width: 94%;
}

.home-icon {
  width: 23px;
  height: 22.3px;
}

.menu-icon {
  width: 26.98px;
  height: 21px;
}

.menu-icon {
  position: relative;
  display: inline-block;
}

.menu-icon .img-top {
  display: none;
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 99;
}

.menu-icon:hover .img-top {
  display: inline;
}

.text-end {
  text-align: end;
}

.home-icon-active {
  position: absolute;
  width: 23px;
  height: 22.3px;
  z-index: 99;
}

.menu-icon-active {
  position: absolute;
  width: 26.97px;
  height: 21px;
  z-index: 199;
  margin-top: 2px;
}

.dashboard-title {
  font-weight: 700;
  color: #3f3d56;
  margin-left: 15px;
}

.dashboard-p {
  margin-left: 15px;
}

.red {
  color: #ff0000;
}

.hover-back:hover rect {
  stroke: rgb(255 255 255);
}

/* .dashboardicon-icon:hover{
    background: #2196F3;
    padding: 0px 25px 0px 25px;
    border-radius: 29px;
    width: 70px;
    height: 38px;
} */
.hover-back:hover {
  background: #2196f3;
  padding: 13px 27px 5px 27px;
  border-radius: 29px;
  stroke: rgb(255 255 255);
}

.hover-back:hover path {
  fill: #ffff;
}

.delete-btn {
  background: #ffff;
  color: black;
  border: none;
}

.delete-btn:hover {
  background: #9e9e9e2b;
  color: black;
  border: none;
}

.delete-btn:active {
  background: #9e9e9e2b;
  color: black;
  border: none;
}

.delete-btn:focus {
  background: #9e9e9e2b;
  color: black;
  border: none;
}

.location-icon-hover:hover path {
  fill: rgb(10 146 255);
  stroke: rgb(10 146 255);
  stroke-width: 0;
}

.delete-property .ant-modal-header {
  border: none;
}

.delete-property .ant-modal-footer {
  border: none;
}

.delete-property .ant-btn {
  background: #dcddde;
  border-radius: 20px;
}

.delete-property .ant-btn-primary {
  background: #fb4e4e;
  border-radius: 20px;
  border: none;
}

.delete-property .ant-btn:hover {
  background: #bfc1c3;
  color: rgba(0, 0, 0, 0.65);
  border-color: #bfc1c3 !important;
}

.delete-property .ant-btn-primary:hover {
  background: #fb4e4ecc;
  color: #ffff;
  border: none;
}

.add-counter-modal .ant-btn-primary {
  background: linear-gradient(90deg, #2196f3, #0065b5);
  border-radius: 20px;
  border: none;
}

.add-counter-modal .ant-btn:hover {
  background: #bfc1c3;
  color: rgba(0, 0, 0, 0.65);
  border-color: #bfc1c3 !important;
}

.add-counter-modal .ant-btn-primary:hover {
  background: linear-gradient(90deg, #2195f3e8, #0064b5dc);
  color: #ffff;
  border: none;
}

.trash-btn {
  background: none;
  border: none;
  box-shadow: none;
  float: right;
}

.trash-btn:hover {
  background: none;
  border: none;
  box-shadow: none;
}

.trash-btn:active {
  background: none;
  border: none;
  box-shadow: none;
}

.trash-btn:focus {
  background: none;
  border: none;
  box-shadow: none;
}

.add-btn {
  width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #bdbdbd54 !important;
  padding: 0px;
  border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  border: none;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  float: left;
  width: 118px;
  height: 118px;
  margin: 0px;
}

.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  left: 0px;
  width: 100%;
  border-radius: 50%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  content: ' ';
}

@media (max-width: 1200px) {
  .card {
    width: 221px !important;
    height: 260px !important;
    box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0), 0 0px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: 20px;
    margin: 30px;
    color: #9c27b0;
  }
}

.ant-modal-footer {
  padding: 10px 20px 30px 0px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
}

.ant-modal-body {
  padding: 0px 80px 0px 25px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-header {
  padding: 25px 110px 0px 25px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

.delete-property h2 {
  line-height: 30px;
}

.gmap {
  height: 400px;
  width: 400px;
}

.gmap-icon {
  position: absolute;
  right: 6%;
  top: 10px;
}

.spin {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.new-tenant-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.card-img {
  width: 100%;
}

.bold {
  display: inline-block;
  font-weight: bold;
}
