.files-upload__preview {
    position: relative;
    background-color: $color-light-gray;
    width: 80px;
    height: 85px;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    img {
        display: block;
        max-width: 100%;
        max-height: 100%; }
    &:hover {
        .files-upload__preview-remove {
            visibility: visible;
            opacity: 1; } }
    &-remove {
        position: absolute;
        top: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        @include transform(translateX(50%) translateY(-50%));
        cursor: pointer;
        @include transition(.15s);
        &:hover {
            svg {
                @include transform(scale(1.1)); } }
        svg {
            display: block;
            width: 18px;
            height: 18px;
            @include transition(.2s);
            path {
                stroke: #FFFFFF;
                &.close-icon-round-outline {
                    fill: $color-error; } } } }
    &-content {
        @extend %flex-center;
        max-width: 100%;
        height: 100%; }
    &-icon {
        svg {
            display: block; } }
    &-title {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        font-size: 9px;
        background: transparentize(#FFFFFF, .25);
        white-space: nowrap;
        max-width: 100%;
        padding: 1px 4px;
        overflow: hidden;
        text-overflow: ellipsis; } }
