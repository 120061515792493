// Background Image Cover
%background-cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center; }

%box-shadow-general {
	box-shadow: $box-shadow-general; }

%color-gradient {
	background: $gradient-general;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; }

%block-white-basics {
	background-color: #FFFFFF {
 }	                    //background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #2F92ED 0%, #00CFAA 100%)

	border-radius: $radius-general;
	@include box-shadow($box-shadow-block); }

%flex-center {
	@include display-flex;
	@include flex-direction(column);
	@include align-items(center);
	@include justify-content(center); }
