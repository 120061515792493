.backdrop-overlay--story-particular {
    .backdrop-overlay {
        &__body {
            width: 680px;
            margin-right: auto;
            margin-left: auto;
            .ant-skeleton {
                background-color: #FFFFFF;
                padding: 2rem;
                border-radius: $radius-general; } } }

    .story-each {
        overflow: auto;
        height: 100vh; } }
