.agoraReactPanel {
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
    &_middle {
        display: flex;
        justify-content: space-between;
        &_reactButtons {
            background-color: #2A2E33;
            border-radius: 5px;
            margin: 0 14px;
            display: flex;
            justify-content: space-between;
            .verticalLine {
                width: 1px;
                height: calc( 100% - 20px );
                margin: 10px 0;
                background-color: #2196F3; }
            button {
                box-shadow: none;
                width: 60px !important; } }
        &_likeCount, &_dislikeCount {
            color: #2196F3;
            background: rgba(33, 150, 243, 0.1);
            border-radius: 4px;
            padding: 10px; } } }
