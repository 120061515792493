// F.O.N.T.S
$font-body: 'Lato', sans-serif, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$font-heading: 'Poppins', sans-serif, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

// C.O.L.O.R.S
// Color Body
$color-body: #444444;
// Color Light
$color-light: #C3C3C3;
$color-lighter: #E8E8E8;
$color-lightest: #F1F2F3;
// Color Dark
$color-dark: #929292;
$color-darker: #626262;
$color-darkest: #272727;
// Color Primary
$color-primary: #2196F3;
$color-primary-light: #DBEBF8;
$color-primary-lighter: transparentize($color-primary, 0.75);
$color-primary-lightest: transparentize($color-primary, 0.9);
// Color Secondary
$color-secondary: #9C27B0;
// Color tertiary
$color-tertiary: #3F3D56;
// Color Success
$color-success: #2AC769;
$color-success-dark: darken($color-success, 5%);
$color-success-darker: darken($color-success, 15%);
$color-success-darkest: darken($color-success, 30%);
// Color Error
$color-error: #FB4E4E;
$color-error-dark: darken($color-error, 5%);
$color-error-darker: darken($color-error, 15%);
$color-error-darkest: darken($color-error, 30%);
// Color Warning
$color-warning: #F6A609;
$color-warning--alt: #FF5400;
// C.o.l.o.r T.o.m.a.t.o
$color-tomato: #AB3F1B;
// Color Text Mute
$color-text-mute: #ADB3BB;
// gray
$color-gray: #E0E0E0;
// Cool gray
$color-cool-gray: #73788F;
// Soft gray
$color-soft-gray: #797979;
// Deep gray
$color-deep-gray: #A8B0BF;
// Light gray
$color-light-gray: #F6F6F6;
// Smooth gray
$color-smooth-gray: #3F4347;
// New gray
$color-new-gray: #8296A9;
// Dark blue
$color-dark-blue: #000F2B;
// Dark bg light
$color-dark-light-bg: #2A2E33;
// dark bg
$color-dark-bg: #222222;
// Outlines
$color-outlines: #DADCE0;

$color-white: #FFFFFF;
$color-light: #A9B0BE;

// drop shadow
$color-dark-shadow: rgba(0,0,0,.5);
// drop down background
$color-dark-drop-bg: rgba(33, 150, 243, 0.1);
// G.U.T.T.E.R
// Gutter General
$gutter: 20px;
$gutter-large: 30px;
// Gutter Half
$gutter-half: $gutter / 2;
// Gutter Quarter
$gutter-quarter: $gutter / 4;

// B.O.R.D.E.R - R.A.D.I.U.S
$radius-general: 6px;
$radius-alt: 5px;
$radius-medium: 3px;
$radius-large: 5px;
$radius-block: 15px;

// B.O.X - S.H.A.D.O.W
$box-shadow-general: 0 1px 5px transparentize(#000000, .8);
$box-shadow-large: 0 4px 30px transparentize(#000000, .85);
$box-shadow-nav: 0 2px 2px #ECECEC;
$box-shadow-nav-dark: 0 1px 10px #000000;
$box-shadow-block: 0 0px 2px #00000065;
$box-shadow-btn: 0 1px 2px #00000015;
// $box-shadow-popups: 0 5px 20px #00000015
$task-cards-shadow: 0 0.5px 1px #00000030;

// G.R.A.D.I.E.N.T
// $gradient-general: linear-gradient(99.34deg, #2196F3 0.93%, #9C27B0 52.03%);
$gradient-general: linear-gradient(to right, $color-primary, #0065b5);
$gradient-alt: linear-gradient(to bottom right, $color-primary , $color-secondary);

// W.I.D.T.H
$side-navigation-width: 280px;
$container-sm-width: 720px;
$container-md-width: 850px;
$container-width: 994px;
$container-lg-width: 1240px;
$container-xl-width: 1440px;
$container-xxl-width: 1840px;

// H.E.I.G.H.T
$main-nav-height: 72px;
$property-nav-height: 52px;
$form-input-height: 40px;

// F.O.R.M
$form-input-background: #FFFFFF;
$form-input-background--alt: #FAFAFA;
$form-input-border: $color-lighter;
$form-input-border--alt: #f2f2f2;
$form-input-border-radius: $radius-alt;
$form-input-border-radius--general: $radius-alt;

// M.O.D.A.L
$modal-title-font-size: 1.5rem;
$modal-border-radius: 5px;

// T.R.A.N.S.I.T.I.O.N
// Transition duration general
$transition-duration-general: 0.3s;
// Transition timing function general
$transition-timing-function-general: ease-out;
// Transition general
$transition-general: all $transition-duration-general $transition-timing-function-general;
// Transition duration fast
$transition-duration-fast: 0.2s;
