.commentList {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;
    &_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        &_left {
            display: flex;
            align-items: center;
            h6 {
                font-family: Lato;
                color: #FFFFFF;
                letter-spacing: 0.1px;
                font-size: 18px; }
            span {
                background: rgba(33, 150, 243, 0.1);
                color: #2196F3;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 2px 22px;
                margin-left: 24px;
                border-radius: 4px;
                font-size: 18px; } }
        &_right {
            display: flex;
            align-items: center;
            p {
                font-family: Lato;
                font-size: 18px;
                letter-spacing: 0.1px;
                color: #FFFFFF; }
            img {
                height: 24px;
                margin-left: 24px; } } }
    &_box {
        overflow-y: auto;
        overflow-x: hidden;
        max-width: 1050px;
        box-shadow: 0px 0px 10px $color-dark-shadow;
        border-radius: 8px;
        padding: 24px;
        min-width: 80%;
        &_item {
            height: 150px;
            background: $color-dark-light-bg;
            border-radius: 6px;
            color: #ffffff;
            padding: 8px;
            font-size: 13px;
            overflow: hidden;
            .userName {
                font-weight: bold;
                color: #2196F3; } } } }
