.pdf-viewer {
    .backdrop-overlay__body {
        @include flex-direction(column);
        @include justify-content(flex-start); }
    .pdf-viewer__menu {
        @include display-flex;
        @include justify-content(center);
        width: 100%;
        margin-top: 4.5rem;
        margin-bottom: 2rem;
        @media #{$lg-screen-and-down} {
            margin-top: 4.5rem;
            margin-bottom: 1rem; }
        .pdf-viewer__menu-list {
            @include display-flex;
            padding-left: 0 !important;
            width: 100%;
            justify-content: space-around !important;
            li {
                list-style: none;
                // margin-left: 20px
                button {
                    font-size: 12px;
                    background: transparent;
                    color: #D9D9D9;
                    padding: 0 10px;
                    height: 1.8125rem;
                    border: 1px dotted #B9B9B9;
                    border-radius: 4px;
                    box-shadow: none;
                    letter-spacing: .5px;
                    &:hover,
                    &:active,
                    &:focus {
                        color: #FFFFFF;
                        border: 1px solid #FFFFFF; }
                    @media #{$lg-screen-and-down} {
                        span:nth-of-type(2) {
                            display: none !important; } } } } } }
    .pdf-viewer__pdf {
        @include display-flex;
        @include justify-content(center);
        width: 100vw;
        overflow: auto;
        @media #{$lg-screen-and-down} {
            justify-content: normal;
            canvas {

                // width: 100vw !important
 } } } }                // height: auto !important


// hide following items on mobile
.rotate-right, .rotate-reset, .zoom-reset {
    @media #{$lg-screen-and-down} {
        display: none; } }
