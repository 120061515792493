/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/

html {
	font-size: 16px;
	scroll-behavior: smooth; }

body {
	font-family: $font-body;
	font-size: .875rem;
	color: $color-body; }

h1, h2, h3, h4, h5, h6 {
	font-family: $font-heading; }

svg {
	display: inline-block; }

/***************************************************************************************************/
/***************************************** /.G.E.N.E.R.A.L *****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/***************************************** C.A.R.O.U.S.E.L *****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/**************************************** /.C.A.R.O.U.S.E.L ****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/********************************************* F.O.R.M *********************************************/
/***************************************************************************************************/

// BUTTON
.ant-btn {
	// height: 2.25rem
	height: 2.5rem;
	box-shadow: 0px 0px 4px #EEEEEE;
	border-radius: $radius-alt; }

.ant-btn-primary {
	background: $color-primary;
	border-color: transparent;
	&:hover,
	&:active,
	&:focus {
		background: darken($color-primary, 4%);
		border-color: darken($color-primary, 5%); } }

.ant-btn-secondary {
	background: $color-secondary;
	border-color: transparent;
	color: #FFFFFF;
	&:hover,
	&:active,
	&:focus {
		background: darken($color-secondary, 4%);
		color: #FFFFFF;
		border-color: darken($color-secondary, 5%); } }

.ant-input,
.ant-input-affix-wrapper,
.ant-form-item-control-input,
.ant-select {
	background-color: $form-input-background; }

.ant-input {
	border-color: $form-input-border;
	border-radius: $form-input-border-radius !important;
	padding-top: .5rem;
	padding-bottom: .5rem;
	&:hover {
		border-color: $color-primary; } }

.ant-form-item-control-input {
	border-radius: $form-input-border-radius;
	.ant-input-affix-wrapper {
		background: unset; } }

.ant-input-affix-wrapper {
	padding-top: .5rem;
	padding-bottom: .5rem;
	border-color: $form-input-border;
	border-radius: $form-input-border-radius; }

.ant-input-group-addon {
	border-color: $form-input-border;
	border-radius: $form-input-border-radius;
	svg {
		display: block; } }

.ant-select {
	display: block;
	border-radius: $form-input-border-radius;
	background: transparent;
	.ant-select-selector {
		background: $color-dark-drop-bg !important; } }

.ant-select-show-search.ant-select:not(.ant-select-customize-input) {
		.ant-select-selector {
			background: $color-dark-light-bg !important;
			color: $color-light;
			outline: 0; } }

.ant-select-dropdown {
	background: $color-dark-bg;
	.ant-select-item-group {
		color: $color-primary; } }
.ant-select-item-option-content {
	color: $color-light; }

.ant-select-item-option-active {
	background: $color-dark-light-bg !important; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background: $color-dark-drop-bg !important;
	height: $form-input-height;
	@include display-flex;
	@include align-items(center);
	border-color: transparent;
	border-radius: $form-input-border-radius;
	padding: 0px 10px; }

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: $form-input-height; }

.ant-collapse-header {
	background: transparent !important;
	color: $color-dark-light-bg !important; }

.ant-form-item-label {
	display: inline-flex;
	@include align-items(center); }

.ant-form-item-explain, .ant-form-item-extra {
	font-size: 12px; }

.ant-form-vertical .ant-form-item {
	&:last-child {
		margin-bottom: 0; } }

.ant-switch-checked {
	background-color: $color-primary; }

.ant-radio-group {
	.ant-radio-button-wrapper {
		&:first-child, {
			border-top-left-radius: $form-input-border-radius;
			border-bottom-left-radius: $form-input-border-radius; }
		&:last-child {
			border-top-right-radius: $form-input-border-radius;
			border-bottom-right-radius: $form-input-border-radius; } } }

.ant-dropdown-menu {
	list-style: none !important;
	background: $color-dark-bg;
	filter: drop-shadow(0px 0px 10px $color-dark-shadow); }

.ant-dropdown-menu-item {
	&:hover {
		background: $color-dark-light-bg; } }

.dropdown-menu-general li span {
	color: $color-light !important; }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background: $color-dark-light-bg; }

.ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
	border-top-right-radius: 0 !important;
	border-bottom-right-radius: 0 !important; }

/***************************************************************************************************/
/******************************************** /.F.O.R.M ********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/********************************************* T.A.B.S *********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** /.T.A.B.S ********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/********************************************* G.R.I.D *********************************************/
/***************************************************************************************************/

.container {
	@media only screen and (max-width: 770px) {
		margin-top: 0; }
	@media #{$xxl-screen-and-up} {
		max-width: 1274px; } }

/***************************************************************************************************/
/******************************************** /.G.R.I.D ********************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** M.O.D.A.L ********************************************/
/***************************************************************************************************/

.ant-modal {
	&.ant-modal--sm {
		width: 440px !important;
		.ant-modal-close {
			top: 38px;
			right: 30px; }
		.ant-modal-content {
			padding: 30px; } } }

.ant-modal-close {
	top: 40px;
	right: 40px;
	.ant-modal-close-x {
		width: unset;
		height: unset;
		@include display-flex; } }

.ant-modal-header {
	border-bottom: 0; }

.ant-modal-header,
.ant-modal-body,
.ant-modal-footer {
	padding: 0; }

.ant-modal-title,
.ant-modal-confirm-title {
	font-size: $modal-title-font-size !important;
	margin-bottom: 1.375rem;
	line-height: inherit; }

.ant-modal-title,
.ant-modal-message {
	color: #000000; }

.ant-modal-content {
	border-radius: $modal-border-radius;
	.ant-input,
	.ant-input-affix-wrapper,
	.ant-form-item-control-input,
	.ant-select {
		background-color: $form-input-background--alt; }
	.ant-modal-header {
		// margin-bottom: 2.25rem
		border-bottom: 0;
		.ant-modal-title {
			margin-bottom: .5rem; } }
	.ant-modal-body {
		margin-top: 2.25rem; }
	.ant-modal-subtitle {
		margin-top: -1.5rem;
		margin-bottom: 2.5rem; } }

.ant-modal-footer {
	margin-top: 2.5rem;
	border-top: 0; }

.ant-modal-confirm {
	.ant-modal-confirm-title {
		line-height: 1; } }

.ant-modal-confirm-content {
	line-break: anywhere; }

/***************************************************************************************************/
/******************************************* /.M.O.D.A.L *******************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/************************************** /.D.A.T.E.P.I.C.K.E.R **************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/*************************************** D.A.T.E.P.I.C.K.E.R ***************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/*************************************** P.A.G.I.N.A.T.I.O.N ***************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/************************************** /.P.A.G.I.N.A.T.I.O.N **************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/************************************ T.O.A.S.T - M.E.S.S.A.G.E ************************************/
/***************************************************************************************************/

.ant-message {
	top: auto;
	right: auto;
	bottom: .5rem;
	left: .5rem;
	width: auto;
	.ant-message-notice {
		// padding: 0
		text-align: left;
		.ant-message-notice-content {
			background-color: $color-tertiary;
			color: #FFFFFF; } } }

/***************************************************************************************************/
/*********************************** /.T.O.A.S.T - M.E.S.S.A.G.E ***********************************/
/***************************************************************************************************/

/***************************************************************************************************/
/****************************************** T.O.O.L.T.I.P ******************************************/
/***************************************************************************************************/

.ant-tooltip-arrow {
	display: none; }
.ant-tooltip-inner {
	background: $gradient-general; }

/***************************************************************************************************/
/***************************************** /.T.O.O.L.T.I.P *****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/***************************************** C.O.L.L.A.P.S.E *****************************************/
/***************************************************************************************************/

.ant-collapse,
.ant-collapse-item,
.ant-collapse-header,
.ant-collapse-content {
	border: 0 !important; }

.ant-collapse,
.ant-collapse-content {
	background-color: transparent !important; }

.ant-collapse-item {
	margin-bottom: 0.625rem; }

.ant-collapse-header {
	background-color: $color-primary-light;
	padding-left: 1rem !important;
	border-radius: $radius-general; }

.ant-collapse-content-box {
	padding-top: 2.25rem !important;
	padding-bottom: 2.25rem !important; }

/***************************************************************************************************/
/**************************************** /.C.O.L.L.A.P.S.E ****************************************/
/***************************************************************************************************/

/***************************************************************************************************/
/******************************************** A.L.E.R.T ********************************************/
/***************************************************************************************************/

.ant-alert {
	padding-left: 44px;
	border-radius: 8px; }

/***************************************************************************************************/
/******************************************* /.A.L.E.R.T *******************************************/
/***************************************************************************************************/
