// Hide scroll bar
.story-sidebar, .story-topbar__categories, .story-topbar__subcategories, .sidebar-left-scrollable, .sidebar-right-scrollable {
    -ms-overflow-style: 0;
    scrollbar-width: 0; }
.story-sidebar::-webkit-scrollbar, .story-topbar__categories::-webkit-scrollbar, .story-topbar__subcategories::-webkit-scrollbar, .sidebar-left-scrollable::-webkit-scrollbar, .sidebar-right-scrollable::-webkit-scrollbar {
    display: none !important; }

// ---------------------------------
.sidebar-left-container {
    @media #{$lg-screen-and-up} {
        height: calc( 100vh - 52px );
        position: sticky;
        top: 52px;
        align-self: flex-start; }
    .sidebar-left-scrollable {
        transition: opacity 0.3s;
        @media #{$lg-screen-and-up} {
            height: 80vh;
            overflow-y: auto; } }
    .sidebar-hide {
        opacity: 0;
        transition: opacity 0.3s; } }
.donate-buttons-wrapper {
    width: auto;
    height: 45px;
    background-color: #222222;
    margin: 25px;
    border-radius: 8px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    @media #{$lg-screen-and-down} {
        border-radius: 0;
        margin: 0 0 20px 0; }
    span {
        font-family: 'Lato', sans-serif;
        color: white;
        font-weight: bold;
        letter-spacing: 0.1px;
        font-size: 14px; }
    button {
        height: 35px;
        width: 35px;
        padding: 8px;
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: none;
        cursor: pointer;
        &:hover {
            background: white; } } }

.story-sidebar {
    top: 52px;
    background-color: $color-dark-bg;
    width: 290px;
    padding: 1rem 0;
    flex-shrink: 0;
    // max-height: 500px
    @include display-flex;
    @include flex-direction(column);
    @include align-items(center);
    // @include box-shadow($box-shadow-block)
    box-shadow: 0px 0px 2px $color-dark-shadow;
    border-radius: 12px !important;
    @media screen #{$lg-screen-and-down} {
        border-radius: 0; }
    &--left {
        left: 0;
        .stories-feed-block {
            &--sidebar {
                padding: 0 !important;
                border: 0;
                .ant-select {
                    width: 100% !important;
                    &-selector {
                        padding: 0; }
                    &-selection-placeholder {
                        opacity: 1;
                        color: $color-white; }
                    &-arrow {
                        svg path {
                            fill: $color-white; } } } } }
        .story-sidebar__nav {
            ul li a {
                white-space: nowrap;
                padding-right: 1rem;
                padding-left: 1rem;
                img {
                    width: 1rem;
                    height: 1rem;
                    margin-right: .5rem; } } } }
    &__nav {
        margin-top: 0;
        ul {
            li {
                a {
                    @include display-flex;
                    @include align-items(center);
                    font-size: 13px;
                    color: inherit;
                    padding: .5rem 1.5rem;
                    cursor: pointer;
                    border-radius: 6px;
                    @include transition(.1s);
                    &:hover {}
                    &.active {
                        background-color: $color-light-gray; }
                    span {
                        margin-right: 1.25rem; }
                    svg {
                        display: block; } } } } }
    &__country,
    &__categories {
        background-color: $color-dark-bg;
        border-radius: $radius-general; }
    &__countryant-collapse-header {
        .ant-select-arrow {
            right: 2px; }
        .ant-select-clear {
            right: 4px;
            font-size: 16px;
            color: $color-dark-bg;
            border-radius: 50%; }
        .ant-select-selector {
            box-shadow: none; }
        .ant-select-selection-search {
            left: 0 !important; }
        .ant-select-selection-placeholder {
            color: $color-dark-bg; } }
    &__categories {
        .ant-collapse {
            &-item {
                margin-bottom: 0;
                &-active {
                    .ant-collapse-header {
                        background-color: $color-light-gray; } } }
            &-header {
                margin-right: 9px;
                margin-bottom: .5rem;
                padding-top: 8px !important;
                padding-bottom: 8px !important;
                border-radius: 6px;
                .story-sidebar {
                    &__collapse {
                        &-header {
                            color: $color-white; }
                        &-icon {
                            display: block;
                            svg {
                                display: block;
                                width: 20px;
                                height: 20px; } } } } }
            &-header,
            &-content {
                color: $color-new-gray !important; }
            &-content {
                .ant-collapse-content-box {
                    padding: 4px 1.25rem 1rem 1.25rem !important; } } }
        &-checkbox-master {
            input[type="radio"] {
                &:after {
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    position: relative;
                    background-color: $color-dark-bg;
                    content: '';
                    display: inline-block;
                    visibility: visible;
                    border: 2px solid $color-primary; } }
            input[type="radio"]:checked {
                &:after {
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    position: relative;
                    background-color: $color-primary;
                    content: '';
                    display: inline-block;
                    visibility: visible;
                    border: 2px solid $color-primary; } }
            @include display-flex;
            @include justify-content(space-between);
            color: $color-white;
            width: 100%;
            cursor: pointer;
            .ant-checkbox-wrapper {
                margin-left: auto;
                .ant-checkbox-inner {} }
            .ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: $color-primary; } } } }
    &__collapse-header {
        @include display-flex;
        img {
            margin-right: .75rem; } }
    &__collapse-body {
        ul {
            li {
                padding-left: 28px;
                &:hover {
                    label {
                        color: $color-white; } }
                label {
                    @include display-flex;
                    @include align-items(center);
                    cursor: pointer;
                    user-select: none; }
                &:not(:last-child) {
                    margin-bottom: .5rem; }
                .story-sidebar__collapse-check {
                    margin-left: auto;
                    visibility: hidden;
                    svg path {
                        stroke: $color-primary; } }

                input[type="radio"]:checked + .story-sidebar__collapse-check {
                    visibility: visible; } } } }
    &__collapse-check {}
    &__block-padded {
        @include display-flex;
        @include align-items(center);
        padding: 10px 24px 10px 20px;
        height: 60px; }
    &__left-divider {
            background-color: #F0F2F5;
            width: 100%;
            height: 10px; } }


.story-sidebar--left {
    margin: 1px 25px;
    @media #{$lg-screen-and-down} {
        margin: 0; }
    .story-topbar {
        display: none;
        @media #{$lg-screen-and-down} {
            display: block; } }
    @media #{$lg-screen-and-down} {
        position: relative;
        top: inherit;
        width: 100%;
        height: auto;
        background-color: $color-light-gray;

        .story-topbar {
            width: 100vw;
            height: auto;
            padding: 0 1rem;
            overflow: hidden;
            &__titlebar {
                display: flex;
                justify-content: space-between;
                align-items: center; }
            &__body {
                width: 100vw;
                .story-topbar__categories {
                    width: 100vw;
                    display: flex;
                    overflow: auto;
                    padding-right: 2rem;
                    .category-btn {
                        transition: none;
                        flex-shrink: 0;
                        height: 70px;
                        width: 70px;
                        padding: 0;
                        margin: 0.3rem 0.5rem 0 0;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        font-size: 10px;
                        .category-icon {
                            height: 30px; }
                        .category-title {
                            position: absolute;
                            width: 70px;
                            text-align: center;
                            bottom: 0;
                            left: 0; }
                        .all-story-icon {
                            svg {
                                height: 50px;
                                width: 70px; }
                            path, circle {
                                stroke: white;
                                fill: $color-primary; } } }
                    .ant-radio-button-wrapper-checked.category-btn {
                            background-color: $color-primary;
                            color: white;
                            .category-icon {
                                filter: brightness(0) invert(1); }
                            .all-story-icon {
                                path {
                                    stroke: $color-primary;
                                    fill: white; } } } } }


            .story-topbar__subcategories {
                margin-top: 1rem;
                width: calc( 100vw - 1rem );
                display: flex;
                overflow: auto;
                padding-right: 2rem;
                text-align: center;
                .ant-radio-button-wrapper {
                    flex-shrink: 0; } } } }




    .story-sidebar__nav {
        @media #{$lg-screen-and-down} {
            display: none; } } }


.image-credit-box {
    padding-top: 24px;
    @media #{$lg-screen-and-down} {
        display: none; }
    .image-credit-link {
        color: #ffffff;
        background: #00000085;
        padding: 4px 8px;
        border-radius: 4px;
        margin-left: 24px;
        &:hover {
            color: #83e6ff; } } }


// For creating highlight effect
#stories-sidebar-left {
    transition: background-color 1000ms linear; }
.highlight {
    background-color: #fff895 !important;
    transition: background-color 500ms linear; }
