.user__profile-social-profile {
    background-color: #F1F2F3;
    padding: 1.5rem;
    @include display-flex;
    @include flex-direction(column);
    height: 100%;
    border-radius: $radius-general; }

.user__profile-social-agreement {
    @include display-flex;
    justify-content: space-between;
    margin-top: 1rem;
    &-text {
        margin-right: .5rem; } }
