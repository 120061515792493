.user-team {
    &__invite {
        @media #{$lg-screen-and-down} {
            width: 100vw; }
        &-wrapper {
            margin-bottom: 3rem; }
        &-heading {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: .5rem; }
        &-subheading {
            font-size: 12px;
            .block-general {
                padding: 2.5rem 2rem; } } }
    &__select-role {
        .ant-form-item-control {
            .ant-radio-group {
                @include display-flex;
                width: 100%;
                label {
                    @include flex(1);
                    text-align: center; } } } }
    .team-role-table-container {
        @media #{$lg-screen-and-down} {
            padding: 0 !important; } }
    &__list {
        .block-general {
            overflow-x: scroll;
            padding: 2.5rem 2rem; } }
    &__change-role {
        width: 6rem;
        max-width: 6rem;
        span[aria-live] {
            display: none !important; }
        .ant-select-selection-item {
            text-transform: capitalize; } } }
