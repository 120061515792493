.story-each__file {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 300px;
    padding: .6125rem;
    border-radius: $radius-general;
    cursor: pointer;
    @extend %flex-center;
    &:hover {
        .story-each__file-name {
            visibility: visible;
            opacity: 1; } }
    &-icon,
    &-name {
        margin-top: auto; }
    &-icon {
        background-color: transparentize(#FFFFFF, .5);
        width: 72px;
        height: 72px;
        border-radius: 50%;
        @include box-shadow(0 2px 3px transparentize(#000000, .925));
        @extend %flex-center;
        svg {
            display: block;
            width: 36px;
            height: 36px; }
        &-text {
            font-size: 10px;
            color: $color-cool-gray;
            margin-top: .8125rem; } }
    &-name {
        color: $color-text-mute;
        visibility: hidden;
        opacity: 0;
        font-size: 11px;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include transition(.2s); }
    &-remaining-count {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 22px;
        font-weight: light;
        color: #FFFFFF;
        @include transform(translateX(-50%) translateY(-50%)); } }
