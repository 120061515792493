.stories {
    margin: -52px 0;
    @media #{$lg-screen-and-down} {
         margin: 0; }
    .stories-container {
        .container-inner {
            display: flex;
            justify-content: space-between;
            @media #{$lg-screen-and-down} {
                display: block;
                border: none; }
            .container-middle {
                position: relative;
                padding: 0 35px;
                @media #{$lg-screen-and-down} {
                    padding: 0; } } } } }

.stories-feed-block {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    padding: 1rem 2rem;
    @media #{$lg-screen-and-down} {
        padding: 1rem 1rem; }
    &--sidebar {
        // background-color: $color-dark-bg
        width: 250px;
        padding: .6125rem .6125rem; } }
