.side-drawer-nav {
    position: fixed;
    top: $main-nav-height;
    right: 0;
    bottom: 0;
    background-color: $color-dark-bg;
    width: 340px;
    padding: 2rem;
    z-index: 999;
    @include display-flex;
    @include flex-direction(column);
    @include box-shadow($box-shadow-general);
    @include transition(.3s ease-out);
    &.invisible {
        @include transform(translateX(340px)); }
    .ant-btn {
        height: 30px;
        @include box-shadow(none);
        svg {
            width: 20px;
            height: 20px; }
        span {
            margin: 0; } }
    &__header {
        @include display-flex;
        &-left {
            .ant-btn {
                background-color: $color-dark-bg;
                padding: 0;
                border-color: $color-dark-bg;
                color: #FFFFFF;
                @include display-flex;
                &:hover,
                &:active,
                &:focus {
                    border: 0;
                    outline: 0; }
                .icon-wrapper {
                    display: block;
                    background-color: #353535;
                    width: 36px;
                    height: 36px;
                    margin-right: .5rem;
                    border-radius: 50%;
                    @include display-flex;
                    @include align-items(center);
                    @include justify-content(center); }
                svg {
                    display: block;
                    margin-right: 0;
                    path {
                        stroke: #FFFFFF; } } } }
        &-right {
            margin-left: auto;
            .ant-btn {
                text-transform: uppercase; } }
        .ant-btn {
            &:not(:first-child) {
                margin-left: .5rem; } } }
    &__body {
        margin-top: 56px;
        &-each {
            margin-bottom: 1.5rem; }
        &-header {
            color: $color-new-gray;
            padding-bottom: .75rem;
            border-bottom: 2px solid #353535;
            border-radius: $radius-general; }
        &-content {
            @include display-flex;
            @include justify-content(space-between);
            gap: 10px;
            padding: 1rem 0;
            &-each {
                @include display-flex;
                @include flex-direction(column);
                @include align-items(center);
                // @include flex(1)
                @include transition(.2s);
                background-color: #353535;
                // margin-top: 8px
                padding: 1rem 1rem;
                border-radius: 6px;
                border: 2px solid #353535;
                cursor: pointer;
                &:hover {
                    background-color: $color-dark-bg;
                    @include box-shadow(0 1px 0 #D2D2D2);
                    .side-drawer-nav__body-content-each-icon {
                        background-color: $color-primary-lightest;
                        @include transform(scale(1.1)); }
                    .side-drawer-nav__body-content-each-label {
 } }                        // color: $color-primary
                &:not(:last-child) {
                    .side-drawer-nav__body-content-each-icon {
                        svg path {
                            fill: $color-primary; } } }
                &-icon {
                    @include display-flex;
                    @include align-items(center);
                    @include justify-content(center);
                    @include transition(.1s);
                    svg {
                        display: block;
                        width: 20px;
                        height: 20px; }
                    svg path {
                            fill: $color-primary; } }

                &-label {
                    font-size: 12px;
                    font-weight: 300;
                    color: #FFFFFF;
                    margin-top: .75rem;
                    text-align: center; } } } }
    &__bg-selection {
        width: 100%;
        &-list {
            @include display-flex;
            @include justify-content(space-between); }
        &-item {
            width: 24px;
            height: 24px;
            border-radius: $radius-alt;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            cursor: pointer;
            opacity: .7;
            &:first-of-type {
                border: 1px solid white; }
            &:hover {
                opacity: 1; }
            &--active {
                outline: 3px solid $color-primary; } } }
    &__footer {
        margin-top: auto;
        @include display-flex;
        @include justify-content(center);
        .ant-btn {
            background-color: #353535;
            color: $color-primary;
            width: 100%;
            height: 34px;
            border-color: #353535;
            @include display-flex;
            @include justify-content(center);
            @include box-shadow($task-cards-shadow);
            &:hover {
                background-color: $color-dark-bg; }
            svg {
                width: 16px;
                height: 16px; } } } }


.backdrop-overlay--side-drawer-nav {
    top: $main-nav-height; }
