.quill {
    .ql-toolbar,
    .ql-container {
        border-color: $form-input-border; }
    .ql-toolbar {
        border-top-right-radius: $form-input-border-radius--general;
        border-top-left-radius: $form-input-border-radius--general; }
    .ql-container {
        font-family: $font-body;
        border-bottom-right-radius: $form-input-border-radius--general;
        border-bottom-left-radius: $form-input-border-radius--general;
        .ql-editor {
            background-color: $form-input-background--alt;
            min-height: 200px;
            &.ql-blank {
                &::before {
                    font-style: normal;
                    color: $color-light; } } } } }

.react-quill-text-counter {
    margin-top: .5rem;
    text-align: right;
    &__helper {
        margin-right: .5rem; } }
