.user-team {
    &__table {
        thead th {
            background-color: unset; } }
    &__member-action-btn {
        text-transform: capitalize; }
    &__role-owner,
    &__status-pending {
        text-align: center;
        .ant-select-arrow {
            display: none; }
        .ant-select-selection-item {
            padding-right: 0 !important; } }
    &__role-owner {
        .ant-select-selector {
            background: $color-primary !important;
            .ant-select-selection-item {
                color: #FFFFFF; } }
        .ant-select-arrow {
            display: none; } } }

.team-member {
    &-info {
        font-size: 14px;
        &-wrapper {
            display: inline-block; } }
    &-name {
        font-weight: 700; }
    &-email {
        color: $color-dark; }
    &__property-access {
        span[aria-live] {
            display: none !important; }
        .ant-select-selector {
            @include flex-wrap(nowrap); } }
    &__property-access-dropdown {
        border-radius: 1rem !important;
        right: 0;
        left: auto !important;
        &-content {
            // padding: 2rem 2rem
            .ant-checkbox-group-item,
            .ant-checkbox-wrapper,
            .ant-radio-wrapper {
                    @include display-flex;
                    @include flex-direction(row-reverse);
                    @include justify-content(space-between);
                    width: 100%;
                    background-color: $color-light-gray;
                    padding: .5rem 1rem;
                    margin-bottom: 10px;
                    border-radius: 5px; }
            .ant-checkbox-wrapper,
            .ant-radio-wrapper {
                margin-left: 0; } } } }

.pa-dropdown-content {
    @include display-flex;
    @include flex-direction(column);
    &__heading {
        margin-bottom: .75rem; } }
