.ant-modal--member-role-info {
    .ant-modal-header {
        .ant-modal-title {
            color: $color-primary; } }
    .ant-modal-body {
        .ant-table-thead {
            tr th {
                font-weight: 600; } }
        .ant-table-tbody {
            tr td svg path {
                stroke: $color-primary; } } } }

.user-team__select-role {
    position: relative;
    .team-member-invite-modal-trigger {
        position: absolute;
        top: -28px;
        left: 82px;
        cursor: pointer;
        z-index: 9;
        svg {
            width: 18px;
            height: 18px; } } }

.user-team__invite-form {
    &-submit {
        .ant-spin {
            color: $color-light;
            margin-right: .5rem; } } }
