.story-each__files {
    margin-top: 1rem;
    height: auto;
    &--2, {
        .story-each {
            &__file {
                &-icon {
                    width: 64px;
                    height: 64px;
                    svg {
                        width: 32px;
                        height: 32px; } } } } }
    &--3,
    &--4,
    &--overflow {
        .story-each {
            &__file {
                &-icon {
                    width: 52px;
                    height: 52px;
                    svg {
                        width: 20px;
                        height: 20px; } } } } }
    &--2 {
        height: 350px; }
    &--3 {
        height: 280px; }
    .ant-row {
        height: 100%;
        .ant-col {
            &--story-files-full {
                @include display-flex;
                @include flex-direction(column);
                position: relative;
                @include display-flex;
                @include flex-direction(column);
                &:nth-child(3) {
                    .story-each__file {
                        height: calc(50% - 1px);
                        &:not(:first-child) {
                            position: absolute;
                            top: calc(50% + 1px);
                            right: 0;
                            bottom: 0;
                            left: 0;
                            height: auto; } } } } } }

    &--overflow {
        .story-each__file--last {
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: transparentize(#000000, .5); } } } }

