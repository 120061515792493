.agoraTabButtons {
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: 'Poppins';
    &_bar {
        width: 60%;
        border-radius: 8px;
        margin-bottom: 28px;
        &_title {
            color: #ffffff !important;
            padding: 14px 14px 12px 12px;
            font-size: 24px;
            text-align: center; }
        &_buttosPanel {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px 5px;
            margin: 10px 0px;
            border-radius: 8px;
            box-shadow: 0px 0px 10px $color-dark-shadow;
            height: 54px;
            .ant-radio-group {
                // tab buttons
                .ant-radio-button-wrapper {
                    background: none;
                    color: #74788D;
                    outline: none;
                    border: none;
                    border-radius: 6px !important;
                    font-size: 14px;
                    height: auto;
                    line-height: 30px;
                    &::before {
                        display: none; }
                    &:focus-within {
                        box-shadow: none !important; } }
                .ant-radio-button-wrapper-checked {
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #2F92ED 0%, #00CFAA 100%);
                    color: #ffffff;
                    &:hover {
                        background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, #2F92ED 0%, #00CFAA 100%); } } }
            .story-each__action-each {
                &:hover {
                    background-color: #00000028; }

                .story-each__action-label {
                    font-size: 16px;
                    color: #74788D; }
                .story-each__action-icon {
                    padding-top: 4px;
                    svg {
                        fill: #ffffff !important;
                        path {
                            stroke: #ffffff !important; } } }
                .story-each__action-count {
                    color: #ffffff;
                    padding-top: 4px;
                    background-color: #ffffff00;
                    font-size: 14px; } } } }
    &_filter {
        width: 60%;
        border-radius: 8px;
        margin-bottom: 20px;
        margin-top: 30px;
        &_buttosPanel {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 12px;
            .ant-select-arrow {
                color: $color-success; }
            .ant-select {
                margin-right: 35px;
                .ant-select-selector {
                    width: 180px !important; } }
            .ant-radio-group {
                // tab buttons
                display: contents !important;
                .ant-radio-button-wrapper {
                    background: $color-dark-bg;
                    margin: 2px 8px;
                    color: $color-light;
                    outline: none;
                    border: none;
                    box-shadow: 0px 0px 10px $color-dark-shadow;
                    border-radius: 6px !important;
                    &::before {
                        display: none; }
                    &:focus-within {
                        box-shadow: 0px 0px 2px $color-success !important;
                        color: $color-white; }
                    &:hover {
                        box-shadow: 0px 0px 2px $color-success;
                        background: none;
                        color: $color-light; }
                    span:nth-child(2) {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        svg {
                            margin-left: 5px; } } }

                .ant-radio-button-wrapper-checked {
                    box-shadow: 0px 0px 2px $color-success !important;
                    color: $color-white;
                    &:hover {
                        box-shadow: 0px 0px 2px $color-success;
                        background: none;
                        color: $color-light; } } }
            .story-each__action-each {
                &:hover {
                    background-color: #00000028; }

                .story-each__action-label {
                    font-size: 16px;
                    color: #74788D; }
                .story-each__action-icon {
                    padding-top: 4px;
                    svg {
                        fill: #ffffff !important;
                        path {
                            stroke: #ffffff !important; } } }
                .story-each__action-count {
                    color: #ffffff;
                    padding-top: 4px;
                    background-color: #ffffff00;
                    font-size: 14px; } } } } }
