.story-modal {
    width: 800px !important;
    @media #{$lg-screen-and-down} {}
    .ant-modal-content {
        padding: 16px 24px;
        @media #{$lg-screen-and-down} {
            padding: 16px 20px; } }
    .ant-modal-body {
        margin-top: 12px !important; } }

#story-form {
    .story-title-wrapper {
        .ant-form-item {
 }            // margin-bottom: 0.375rem
        .ant-form-item-label {
            @include display-flex;
            @inclue align-items(center) {} } }
    .story-short-counter {
        display: block;
        text-align: right;
        margin: -0.75rem 0 1.5rem 0; }
    .story-content {
        margin: .3125rem 0; } }

.story-files {
    margin-top: 1.3125rem;
    &-heading {
        margin-bottom: 1rem; } }


.modal-close {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    display: inline-block;
    cursor: pointer; }
