// LOGO
.main-nav__logo {
    display: flex;
    align-items: center;
    .main-nav-app-logo {
        // margin-top: 4px !important
        display: flex;
        align-items: center;
        .app-logos {
            .icon-logo {
                height: 18px; } } }

    .Hamburger {
        color: white;
        box-shadow: none;
        @media #{$lg-screen-and-up} {
            display: none; } }
    .main-nav__logo-img,
    svg,
    img {
        display: block;
        // For img
        // width: 112px
        // For svg
        // width: 120px
        // height: 31px
        @media #{$xxl-screen-and-up} {
            // For img
            // width: 124px
            // For svg
            // width: 132px
 } } }            // height: 34px
