/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/

@font-face {
    font-family: "Borealis";
    src: url('../../assets/fonts/borealisregular.ttf'); }

html,
body,
#root {
    min-height: 100vh; }

body {
    line-height: 1.4; }

strong {
    font-weight: bold; }

em {
    font-style: italic; }

ul,
ol {
    padding-left: 1.5rem;
    list-style-position: inside;
    &.clean {
        padding-left: 0;
        list-style: none !important; } }

ul {
    list-style-type: disc !important; }
ol {
    list-style-type: decimal !important; }

.container,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    margin-right: auto;
    margin-left: auto;
    padding-right: $gutter;
    padding-left: $gutter;
    @include transition(max-width .2s); }

.container-fluid {
    padding-right: $gutter;
    padding-left: $gutter; }

.container-sm,
.container--sm {
    max-width: $container-sm-width !important; }

.container-md,
.container--md {
    max-width: $container-md-width !important; }

.container {
    max-width: $container-width; }

.container-lg,
.container--lg {
    max-width: $container-lg-width !important; }

.container-xl,
.container--xl {
    max-width: $container-xl-width !important; }

.container-xxl,
.container--xxl {
    max-width: $container-xxl-width !important; }

.container-block {
    padding: 2rem;
    @extend %block-white-basics; }

.modal-title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: .75rem;
    line-height: 40px; }

.modal-message {
    margin-bottom: 1rem; }

.ant-modal--xl {
    width: 1120px !important;
    .ant-modal-content {
        padding: 3rem;
        @media #{$lg-screen-and-down} {
            padding: 3rem 1rem; } }
    .ant-modal-body,
    .ant-modal-footer {
        padding: 0; } }

.ant-modal--fullscreen {
    position: fixed;
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
    width: unset !important;
    padding-bottom: 0;
    .ant-modal-content {
        height: 100%;
        @include display-flex;
        @include flex-direction(column);
        .ant-modal-body {
            @include flex(1); } } }

.preview-base {
    background-color: #FFFFFF;
    box-shadow: $box-shadow-block;
    border-radius: $radius-general;
    @include transition(box-shadow 0.1s);
    &:hover {
        box-shadow: 0 3px 8px #EEEEEE; }
    &:focus {
        outline: none; } }

.ant-form-item--label-last {
    .ant-form-item-control {
        order: 1; }
    .ant-form-item-label {
        order: 2;
        margin-left: 1rem; } }

.form-label-custom {
    margin-bottom: 1rem; }

.form-label-custom--icon {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 99%; }

.spin-wrapper {
    height: 100%;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center); }

.dropdown-general {
    .ant-dropdown-menu-item,
    a {
        @include display-flex;
        @include align-items(center);
        svg {
            margin-right: 1rem; } } }

// TEXT
.text-gradient {
    background: $gradient-alt;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }

// FORM

.ant-form-item--general {
    .ant-form-item-control-input,
    .ant-form-item-control-input .ant-input {
        border-radius: $form-input-border-radius--general; } }

.ant-input--general {
    border-radius: $form-input-border-radius--general !important; }

.ant-btn {
    &-squared {
        border-radius: $radius-general !important; }
    &-warning {
        background-color: $color-warning--alt;
        color: #FFFFFF;
        border-color: darken($color-warning--alt, 2%);
        &-outlined {
            color: $color-warning--alt;
            border-color: $color-warning--alt; } }
    &-fluid {
        width: 100%;
        max-width: 100%; } }

.ant-btn-icon {
    @include display-flex;
    @include align-items(center);
    svg {
        margin-left: .6125rem; }
    &--left {
        svg {
            margin-left: 0;
            margin-right: .6125rem; } }

    &.ant-btn-primary {
        svg,
        svg path {
            stroke: #FFFFFF; } } }

// HEADING

.page-heading {
    font-size: 18px;
    font-weight: 500;
    color: #3F3D56; }

// TABS
.tabs-general {
    @include display-flex;
    border-bottom: 2px solid $color-lighter;
    .tabs-general__tab {
        @include display-flex;
        @include align-items(center);
        font-weight: 600;
        color: inherit;
        margin-bottom: -2px;
        padding: 1rem 0;
        border-bottom: 2px solid transparent;
        &:hover {
            color: $color-primary; }
        &.active {
            // font-weight: 600
            color: $color-primary;
            border-bottom-color: $color-primary;
            svg,
            svg path,
            svg circle {
                stroke: $color-primary; }
            &:not(.tabs-general__tab--icon-no-fill) {
                svg,
                svg path {
                    fill: $color-primary; } } }
        &:not(:last-child) {
            margin-right: 6rem;
            @media #{$lg-screen-and-down} {
                margin-right: 2.5rem; } }

        svg {
            stroke: $color-cool-gray;
            margin-right: .75rem; } } }

// DROPDOWN
.dropdown-menu-general {
    padding: 16px 16px;
    border-radius: $radius-block;
    &--collapsed {
        li {
            padding: 5px 12px !important; } }
    &--centered {
        text-align: center;
        li {
            @include justify-content(center); } }
    &--large {
        padding: 24px; }
    li {
        color: $color-primary;
        padding: 10px 15px;
        @include display-flex;
        @include align-items(center);
        border-radius: $radius-alt;
        &.ant-dropdown-menu-item-danger {
            svg {
                path {
                    stroke: $color-error; } }
            &:hover {
                span {
                    color: #FFFFFF; }
                svg {
                    path {
                        stroke: #FFFFFF; } } } }
        a {
            color: $color-primary !important;
            margin: 0;
            padding: 0;
            @include display-flex;
            @include align-items(center); }
        span {
            color: $color-body; }
        svg {
            width: 20px;
            height: 20px;
            margin-right: .8125rem; } } }

small,
.small {
    font-size: 12px;
    line-height: 1.4; }

.block-general {
    padding: 2.5rem 2rem;
    @extend %block-white-basics;
    @media #{$lg-screen-and-down} {
        padding: 1.5rem !important; } }

.addon-after-action {
    .ant-input-group-addon {
        background-color: $color-primary;
        border-color: $color-primary;
        cursor: pointer;
        svg path {
            stroke: #FFFFFF; }
        &:hover {
            background-color: darken($color-primary, 6%);
            border-color: darken($color-primary, 6%); } } }

/***************************************************************************************************/
/****************************************** G.E.N.E.R.A.L ******************************************/
/***************************************************************************************************/
