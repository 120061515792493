.commentBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 24px 0;
    &_label {
        color: white;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        padding: 14px 0;
        width: 460px; }
    .writeArea {
        width: 60%;
        position: relative;
        &_textarea {
            width: 100%;
            min-height: 100px;
            padding: 12px 20px;
            box-sizing: border-box;
            border-radius: 4px;
            background-color: $color-dark-light-bg;
            font-size: 16px;
            resize: none;
            border: 0;
            box-shadow: 0px 4px 4px $color-dark-shadow;
            color: $color-white;
            &:focus {
                outline: none !important; } }
        &_submitBtn {
            position: absolute;
            top: 0;
            right: 0;
            color: #b4b4b4;
            box-shadow: none; } } }
