// MAIN NAV USER
.main-nav__user {
    margin-left: 2.25rem;
    @media #{$lg-screen-and-down} {
        margin-left: 0.5rem; }
    &:hover,
    &--active {
        .main-nav__user-img-border {
            visibility: visible !important;
            opacity: 1 !important; } }
    .main-nav__user-wrapper {
        @include display-flex;
        @include align-items(center);
        cursor: pointer;
        &:hover,
        &.ant-dropdown-open {
            .main-nav__user-img-icon {
                svg path {
 } }                    // stroke: #FFFFFF !important
            .main-nav__user-img-border {
                visibility: visible;
                opacity: 1; } }
        .main-nav__user-img {
            position: relative;
            background-color: $color-cool-gray;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            width: 34px;
            height: 34px;
            margin-right: .25rem;
            border-radius: 50%;
            @include display-flex;
            @include align-items(center);
            @include justify-content(center);
            &-icon {
                svg {
                    display: block;
                    width: 22px;
                    height: 22px;
                    path {
                        stroke: #2A2E33;
                        @include transition(all .2s); } } }
            &-border {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                @include transition(all .2s);
                svg {
                    width: 100%;
                    height: 100%; } } }
        .main-nav__username {
            pointer-events: none; }
        .anticon-down {
            color: $color-light;
            margin-left: .3125rem; } } }

.main-nav__user-dropdown-menu {
    min-width: 170px; }


// LANGUAGE TOGGLE
.main-nav__language {
    margin-left: 28px;
    cursor: pointer;
    &-wrapper {
        position: relative;
        width: 30px;
        height: 30px;
        color: $color-deep-gray;
        padding: 2px;
        text-transform: uppercase;
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        @include transition(color .2s);
        img {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0; }
        &:hover,
        &.ant-dropdown-open {
            color: #FFFFFF; } }
    &-inner {
        font-size: 13px;
        @include transform(translateX(-1px)); } }

.ant-dropdown-menu-item--language-toggle {
    &:hover {
        background-color: unset !important;
        cursor: default; } }

.main-nav {
    &__language {
        &-toggle {
            width: 100%;
            @include display-flex;
            @include justify-content(space-between); }
        &-each {
            color: $color-cool-gray;
            padding: 2px 14px;
            border: 1px solid $color-gray;
            border-radius: 5px;
            @include flex-basis(40%);
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            &--active {
                background-image: $gradient-general;
                color: #FFFFFF;
                border: none; } } } }

.main-nav__language-dropdown-menu {
    .ant-dropdown-menu-item {
        background-color: $color-light-gray;
        color: $color-cool-gray;
        padding: 4px 24px;
        border-radius: 8px;
        text-transform: uppercase;
        &:not(:last-child) {
            margin-bottom: 4px; }
        &--active {
            background-color: lighten($color-primary, 40%);
            color: $color-dark-blue; } } }
