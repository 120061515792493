.story-card {
    background-color: transparent;
    border-radius: 8px;
    padding: 1rem;
    position: relative;
    box-shadow: 0px 0px 10px $color-dark-shadow;
    &_saveButton {
        position: absolute;
        z-index: 100;
        top: 24px;
        right: 24px;
        background-color: #343435;
        padding: 6px 12px 4px 12px;
        border-radius: 8px;
        .save-icon-outlined-outline {
            stroke: #ffffff !important; }
        .save-icon-outlined-tick {
            fill: #ffffff !important; }
        .story-each__action-save--active {
            .save-icon-outlined-outline {
                stroke: none !important; } }
        span {
            color: #ffffff;
            height: 24px;
            width: 24px; } }
    .story-each__files {
        margin-top: 0 !important; } }
