.saving-status {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    padding: .5rem 1rem;
    min-width: 6.25rem;
    height: $form-input-height;
    border: 1px solid darken($color-lighter, 2%);
    border-radius: $radius-large;
    @include display-flex;
    @include align-items(center);
    &--saving {
        background: $color-lighter; }
    &--saved {
        background: mix($color-success, #FFFFFF, 30%);
        color: darken($color-success, 25%);
        border-color: mix($color-success, #FFFFFF, 40%);
        svg path {
            fill: darken($color-success, 15%); } }
    &--failed,
    &--invalid_email {
        background: mix($color-error, #FFFFFF, 30%);
        color: darken($color-error, 40%);
        border-color: mix($color-error, #FFFFFF, 40%);
        svg path {
            fill: darken($color-error, 40%); } }
    .saving-status__icon {
        margin-right: .6125rem;
        svg {
            display: block;
            font-size: 18px;
            margin: 0; }
        .ant-spin {
            display: block;
            .anticon-loading {
                font-size: 18px; } } }

    .saving-status__icon,
    .saving-status__icon svg,
    .saving-status__icon .ant-spin,
    .saving-status__icon .ant-spin .anticon-loading {
        width: 18px;
        height: 18px; } }
