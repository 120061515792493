.welcome-message {
    background-color: #FFFFFF;
    padding: 40px 2rem 60px 2rem;
    border-radius: 2px;
    width: 400px;
    max-width: 400px;
    .welcome-message__header {
        text-align: center;
        .welcome-message__logo {
            width: 160px; } }
    .welcome-message__body {
        color: #737373;
        margin: 20px 0 3rem 0;
        line-height: 1.4; }
    .welcome-message__footer {
        text-align: center;
        .ant-btn {
            box-shadow: 0 4px 4px rgba(#000000, .25); } } }
