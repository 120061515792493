.story-share-popover-content {
    &__title {
        font-weight: bold;
        color: #000000; }
    &__icons {
        margin-top: 1.25rem;
        img {
            width: 30px;
            height: 30px;
            cursor: pointer;
            &:not(:last-child) {
                margin-right: .5rem; } } }
    &__copyable {
        background-color: $color-light-gray;
        margin-top: 10px;
        padding: 4px 8px;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: $radius-general; }
    .ant-btn {
        display: block;
        margin-top: 1.25rem;
        margin-right: auto;
        margin-left: auto;
        height: 32px;
        padding-right: 8px;
        padding-left: 8px; } }


.ant-popover--story-share {
    .ant-popover-inner {
        border-radius: $radius-alt;
        &-content {
            padding: 16px 22px; } } }
