.story-create {
    background-color: $color-dark-bg;
    margin-top: 0;
    margin-bottom: 1.25rem;
    cursor: pointer;
    @include display-flex;
    @include align-items(center);
    filter: drop-shadow(0px 0px 2px $color-dark-shadow);
    border-radius: 6px;
    @media #{$md-screen-and-up} {
        margin-top: 25px; }
    @media #{$lg-screen-and-down} {
        flex-direction: column;
        margin-top: 5px; }
    &__user {
        @include display-flex;
        @include align-items(center);
        &-display-name {
            color: $color-light;
            margin-left: .75rem; }
        @media #{$lg-screen-and-down} {
            display: none; } }
    &__message {
        font-size: 1rem;
        background-color: $color-dark-light-bg;
        color: $color-white;
        margin-left: .75rem;
        padding: .75rem 1.5rem;
        border-radius: 4rem;
        @include flex(1);
        @media #{$lg-screen-and-down} {
            margin: 0;
            width: 100%; } } }
