.story-each {
    background-color: $color-dark-bg;
    width: 100%;
    margin-top: 1.25rem;
    padding-bottom: 13px !important;
    @extend %block-white-basics;
    color: $color-light;
    &__top {
        @include display-flex;
        @include align-items(center);
        justify-content: space-between;
        @media #{$lg-screen-and-down} {
            flex-direction: column;
            align-items: normal; }

        .story-each_top__left {
            display: flex;
            align-items: center; }
        .story-each_top__right {
            display: flex;
            align-items: center; } }
    &__username {
        margin-left: 1rem; }
    &__date-posted {
        color: $color-deep-gray;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        @media #{$lg-screen-and-down} {
            margin-left: 0; } }
    &__action-save {
        margin-left: auto;
        cursor: pointer;
        svg path {
            &.save-icon-outlined-outline {
                stroke: $color-deep-gray; }
            &.save-icon-outlined-tick {
                fill: $color-deep-gray; } }
        &:not(.story-each__action-save--active):hover {
            svg path {
                &.save-icon-outlined-outline {
                    stroke: $color-cool-gray; }
                &.save-icon-outlined-tick {
                    fill: $color-cool-gray; } } }
        &--active {
            svg path {
                &.save-icon-outlined-outline {
                    fill: $color-primary;
                    stroke: $color-primary; }
                &.save-icon-outlined-tick {
                    fill: #FFFFFF; } } } }
    &__action-more {
        margin-left: 2rem;
        cursor: pointer;
        &:hover,
        &.ant-dropdown-open {
            svg path {
                stroke: $color-cool-gray; } }
        svg path {
            stroke: $color-deep-gray; } }
    &__title {
        font-size: 1rem;
        font-weight: 600;
        color: $color-white;
        margin-top: 1.25rem; }
    &__content {
        p {
            color: $color-light;
            margin-top: 1rem; }
        iframe.ql-video {
            width: 100%;
            height: 345px; } }
    &__image {
        margin-top: 1rem; } }
